import React from 'react'

function RVDisplay({sub, index, txtSize, rollSize, valWidth, displayWidth, valMargin, useShortDisplay, fullHeight}) {

    //pass in relic's .subs field!!!! 
    //txtsize and val width can be changed if u have space issues

    if (sub === 'invalid sub'){
        return (
            <div className='rvdisplay'>
                <p>Invalid</p>
            </div>
        )
    }
    else if (sub.rollCombo === 'Error') {
        return (
            <div className='rvdisplay'>
                <p style={{fontSize: txtSize ? txtSize : '14px',}}>Data error</p>
                <div style={{width: valWidth ? valWidth : '50px', display: 'flex', justifyContent: 'flex-end'}}>
                    <p style={{fontSize: txtSize ? txtSize : '14px',}}>{sub.value}</p>
                </div>
            </div>
            
        )
    }

    const getShortDisplay = (textIn) => {
        switch (textIn.toLowerCase()){
            case ('crit dmg'):
                return 'CRIT D.'
            case ('crit rate'):
                return 'CRIT R.'
            case ('break effect'):
                return 'Break%'
            case ('effect hit rate'):
                return 'EHR%'
            case ('effect res'):
                return 'RES%'
            default:
                return textIn
        }
    }

    const renderIcons = sub.rollCombo
    .sort((a, b) => {
        return a-b
    })
    .map((curr, index) => {
        switch (curr){
            case 0:
                return <span key={`roll${index}`} style={{color: '#c2855d', fontSize: rollSize ? rollSize : '14px'}}>▃</span>
            case 1:
                return <span key={`roll${index}`} style={{color: '#ffb554', fontSize: rollSize ? rollSize : '14px'}}>▅</span>
            case 2:
                return <span key={`roll${index}`} style={{color: '#ffe675', fontSize: rollSize ? rollSize : '14px'}}>▇</span>
            default:
                return <span key={`roll${index}`} style={{color: '#ababab', fontSize: rollSize ? rollSize : '14px'}}></span>
        }   
    })

    return (
        <div className='rvdisplay' style={index % 2 === 0 ? {backgroundColor: 'rgba(0, 0, 0, 0.25)', height: fullHeight ? fullHeight : '26px' } : {}}>
            <p className={displayWidth ? 'fade-text' : ''} style={sub.display === 'Dead' 
                ? {fontSize: txtSize ? txtSize : '14px', fontWeight: 100, fontStyle: 'italic', color: '#ababab', textDecoration: 'line-through',}
                : {width: displayWidth ? displayWidth : '', margin: valMargin ? valMargin : '',  fontSize: txtSize ? txtSize : '14px', fontWeight: sub.weight >= 0.5 || sub.weight === undefined ? 600 : 300, color: sub.weight >= 1 || sub.weight === undefined ? 'white' : sub.weight >= 0.5 ? "#b8b8b8" : '#4d4d4d'}
            }>{useShortDisplay ? getShortDisplay(sub.display) : sub.display}</p>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='sub-rolls-icons'>
                    {renderIcons}
                </div>
                <div style={{width: valWidth ? valWidth : '50px', display: 'flex', justifyContent: 'flex-end'}}>
                    <p style={{fontSize: txtSize ? txtSize : '14px', color: sub.weight >= 1 || sub.weight === undefined ? 'white' : sub.weight >= 0.5 ? "#b8b8b8" : '#4d4d4d'}}>{sub.value}</p>
                </div>
            </div>
        </div>
    )
}

export default RVDisplay