import React from 'react'
import { Link } from 'react-router-dom'

function MobileMenu({user}) {
  return (
    <div className='mobile-menu'>
        <Link className='mobile-menu--item' to="/leaderboards">
            <img alt="" src={require('../assets/icons/leaderboards-icon.png')}/>
            Top Players
        </Link>
        <Link className='mobile-menu--item' to="/relic-scorer">
            <img alt="" src={require('../assets/icons/score-icon.png')}/>
            Relic Scorer
        </Link>
        <Link className='mobile-menu--item' to="/guides">
            <img alt="" src={require('../assets/icons/book-icon.png')}/>
            Current Meta
        </Link>
        <Link className='mobile-menu--item' to="/contribute">
            <img alt="" src={require('../assets/icons/heart-icon.png')}/>
            Contribute
        </Link>
        {user === null && <Link className='mobile-menu--item' to="/sign-up">
            <img alt="" src={require('../assets/icons/account-icon.png')}/>
            Account
        </Link>}
        {user !== null && <Link className='mobile-menu--item' to="/dashboard">
            <img alt="" src={require('../assets/icons/account-icon.png')}/>
            Dashboard
        </Link>}
    </div>
  )
}

export default MobileMenu