import React from 'react'
import { invValueRank, invValueColor } from '../../utils/scoreStyle'
import { getSignature } from '../../utils/signatureParse';
import { Link } from 'react-router-dom';

function InventoryInfo({currInventory}) {

  const signature = getSignature(currInventory.signature)

  return (
    <div className='inventory-info' >
      <img alt="" src={(() => {
        try {
          return require(`../../assets/pfp/${currInventory.pfp}.png`);
        } catch (e) {
          return require(`../../assets/pfp/no-texture.png`);
        }
      })()} style={{width: '75px', height: '75px', margin: '8px', borderRadius: '999px', border: '1px solid #474239'}}/>
      <div>
          <Link to={`/uid/${currInventory.uid}`} style={{fontSize: '18px', margin: '4px', color: 'white'}}>{currInventory.displayName}</Link>
          <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{ width: '375px'}}>
              <p style={{ margin: '4px'}}>Inventory Value: <b style={{color: invValueColor(currInventory.invScore)}}>{Math.floor(currInventory.invScore * 10) /10} RS</b> ({invValueRank(currInventory.invScore)})<br /><small style={{fontSize: '9px'}}>UID: {currInventory.uid}</small></p>
              </div>
              <p style={{ margin: '4px', opacity: '50%', zIndex: 1, color: signature.color}}>💬 {signature.text}<br />‎ </p>
              <p></p>
          </div>
      </div>
      <img alt="" className="inventory-info--banner" src={require('../../assets/default-banner.png')}/>
    </div>
  )
}

export default InventoryInfo