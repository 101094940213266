import React from 'react'
import CustomBasis from './CustomBasis';
import CharacterSet from './CharacterSet';

function BasisForm({chars, basis, setBasis, charWeightmap, setCharWeightmap, charsetType, setCharsetType, customWeightMap, setCustomWeightMap}) {
  return (
    <div className='basis-form'>
        <b>score basis: </b>
        <small>(stat ignore/weightings)</small>
        <div>
            <select value={basis} onChange={e => setBasis(e.target.value)} className='substat-dropdown' style={{marginTop: '16px', maxWidth: '200px'}}>
            <option value='0'>No Criteria</option>
            <option value='character'>Character</option>
            <option value='1'>General DPS</option>
            <option value='2'>General DoT</option>
            <option value='4'>General Break DPS</option>
            <option value='3'>General Support</option>
            <option value='custom'>Custom</option>
            </select>
            {basis === 'character' && <select className='substat-dropdown' value={charsetType} onChange={e => setCharsetType(e.target.value)}>
            <option value='1'>Crit DPS</option>
            <option value='2'>DoT</option>
            <option value='4'>Break DPS</option>
            <option value='3'>Support</option>
            </select>}
        </div>
        {basis === 'character' && <CharacterSet chars={chars} charsetType={charsetType} charWeightmap={charWeightmap} setCharWeightmap={val => setCharWeightmap(val)}/>}
        {basis === 'custom' && <CustomBasis chars={chars} customWeightMap={customWeightMap} setCustomWeightMap={setCustomWeightMap}/>}
    </div>
  )
}

export default BasisForm