import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'

function Header({mobile, user, loading}) {

  const navigate = useNavigate()
  const [inputSearch, setInputSearch] = useState('')
  const handleSearch = (e) => {
    e.preventDefault()
    setInputSearch('')
    return navigate(`/search?=${inputSearch}`)
  }

  if (!mobile) {
    return (
      <div className='header' style={{justifyContent: 'center'}}>

        <div className='header-inner'>
          <div className='header-items'>  
            <Link style={{height: '50%', margin: '0px 16px 0px 16px'}} to='/'>
              <img alt="scoremyrelic.com" src={require('../assets/logo.png')} style={{height: '100%'}}/>
            </Link>
            <form onSubmit={(e) => handleSearch(e)} className='header-search'>
              <button>
                <img alt='Start search' src={require('../assets/icons/search-icon.png')}/>
              </button>
              <input value={inputSearch} onChange={e => setInputSearch(e.target.value)} placeholder="Search UID or Name"></input>
            </form>
            <div style={{height: "100%", display: 'flex', alignItems: 'center'}}>
              <Link className="header-item" to="/leaderboards">Top Players</Link>
              <Link className="header-item" to="/relic-scorer">Relic Scorer</Link>
              <Link className="header-item" to="/guides">Current Meta</Link>
              <Link className="header-item" to="/contribute">Contribute</Link>
            </div>
          </div>

          {(!loading && !user) && 
            <>
              <Link className="header-btn" to="/login"><img alt='' style={{width: '16px', marginRight: '4px'}} src={require("../assets/icons/login.png")}/>Log in</Link>
              <Link className="header-btn" style={{backgroundColor: '#00A1ED'}} to="/sign-up">Claim your UID</Link>
            </>
          }
          {
            (!loading && user !== null) &&
            <div style={{height: '100%', display: 'flex', marginRight: '16px', gap: '8px', alignItems: 'center'}}>
              <div style={{height: '42px', aspectRatio: '1/1', borderRadius: '999px'}}>
                <img
                  src={user.pfpurl === '' ? require('../assets/pfp/no-texture.png') : user.pfpurl}
                  style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '999px', cursor: 'pointer'}}
                  onClick={() => window.location.href = "/dashboard"}
                  alt='User'
                />
              </div>
              <a href='/dashboard' className='hover-link'>{user.displayName}</a>
            </div>
          }
        </div>
        
      </div>
    )
  }

  else {
    return (
      <div className='header'>
        <Link style={{height: '50%', margin: '0px 16px 0px 16px'}} to='/'>
          <img alt="scoremyrelic.com" src={require('../assets/logo.png')} style={{height: '100%'}}/>
        </Link>
        <form onSubmit={(e) => handleSearch(e)} className='header-search'>
          <button>
            <img alt='Start search' src={require('../assets/icons/search-icon.png')}/>
          </button>
          <input value={inputSearch} onChange={e => setInputSearch(e.target.value)} placeholder="Search UID or Name"></input>
        </form>
      </div>
    )
  }
}

export default Header