import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import inUserService from '../services/in-user-service'
import dbService from '../services/db-service'
import { debounce } from 'lodash'

function Dashboard({user}) {

    const MAX_SIZE = 1 * 1024 * 1024 //upload size reinforced in backend
    const [showLogout, setShowLogout] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [vanityurl, setVanityurl] = useState('')
    const [initialVanityurl, setInitialVanityurl] = useState('')
    const [pfpUpload, setPfpUpload] = useState(false)
    const [pwdObj, setPwdObj] = useState({
        currPassword: '',
        newPassword: ''
    })
    const [pwdConfirm, setPwdConfirm] = useState('')
    const [lockCharPanel, setLockCharPanel] = useState(false)
    const [accountDelPanel, setAccountDelPanel] = useState(false)
    const [accountDelConf, setAccountDelConf] = useState('')
    const [accountDelPwd, setAccountDelPwd] = useState('')
    const [showMsg, setShowMsg] = useState(false)
    const [chars, setChars] = useState([])
    const [lockCharArray, setLockCharArray] = useState([])
    const [changedProfile, setChangedProfile] = useState(false)

    const getChars = debounce(async () => {
        const version = await dbService.getVersion()
        if (version){
          setChars(JSON.parse(localStorage.getItem('dbCharacters')))
        }
        else{
          setChars(await dbService.getChars())
        }
    }, 300)

    const handleLogout = () => {
        localStorage.setItem('token', null)
        window.location.href = "/login"
    }
    
    const handleChangeVanity = async () => {
        setBtnLoading(true)
        try{
            await inUserService.changeVanity(vanityurl, localStorage.getItem('token'))
            window.location.reload()
        }catch(e){
            alert(e.response.data.error)
            setBtnLoading(false)
        }
    }

    const handleChangeLockedChars = async () => {
        setBtnLoading(true)
        try{
            await inUserService.changeLockedChars(lockCharArray, localStorage.getItem('token'))
            window.location.reload()
        }catch(e){
            alert(e.response.data.error)
            setBtnLoading(false)
        }
    }

    const handleChangePfp = async (e) => {
        try{
            if (e.target.files[0].size > MAX_SIZE){
                alert('File is too large')
                e.target.value = null
                return
            }
            setPfpUpload(false)
            setShowMsg(true)
            setBtnLoading(true)
            const formData = new FormData()
            formData.append('pfp', e.target.files[0])
            await inUserService.changePfp(formData, localStorage.getItem('token'))
            window.location.reload()
        }catch(e){
            alert(e.response.data.error)
            setBtnLoading(false)
            setShowMsg(false)
        }
    }

    const handleDelPfp = async () => {
        try{
            setPfpUpload(false)
            setBtnLoading(true)
            await inUserService.delPfp(localStorage.getItem('token'))
            window.location.reload()
        }catch(e){
            alert(e.response.data.error)
            setBtnLoading(false)
        }
    }

    const handleChangeBanner = async (e) => {
        try{
            if (e.target.files[0].size > MAX_SIZE){
                alert('File is too large')
                e.target.value = null
                return
            }
            setBtnLoading(true)
            setShowMsg(true)
            const formData = new FormData()
            formData.append('banner', e.target.files[0])
            await inUserService.changeBanner(formData, localStorage.getItem('token'))
            window.location.reload()
        }catch(e){
            alert(e.response.data.error)
            setBtnLoading(false)
            setShowMsg(false)
        }
    }

    const handleDelBanner = async () => {
        try{
            setBtnLoading(true)
            await inUserService.delBanner(localStorage.getItem('token'))
            window.location.reload()
        }catch(e){
            alert(e.response.data.error)
            setBtnLoading(false)
        }
    }

    const handleChangePwd = async () => {
        if (pwdConfirm !== pwdObj.newPassword || pwdConfirm === '') return alert('Password confirm does not match!')
        try{
            const response = await inUserService.resetPassword(pwdObj, localStorage.getItem('token'))
            localStorage.setItem('token', response.token)
            window.location.reload()
        }catch(e){
            alert(e.response.data.error)
        }
    }

    const handleDelAccount = async (confString) => {
        if (confString !== `Permanently delete account ${user.username}`) return alert('Confirmation input does not match.')
        try{
            await inUserService.delAccount(accountDelPwd, localStorage.getItem('token'))
            window.location.href = "/sign-up"
        }catch(e){
            alert(e.response.data.error)
        }
    }

    useEffect(() => {
        if (user !== null){
            if (user.lockedChars) {setLockCharArray(user.lockedChars)}
            if (user.vanityurl){
                setInitialVanityurl(user.vanityurl)
                setVanityurl(user.vanityurl)
            }
            
        }
        document.title = `Dashboard | ${user ? user.displayName : ''}`
    }, [user])

    useEffect(() => {
        getChars()
        window.scrollTo(0, 0)
        document.title = `Dashboard | `
        // eslint-disable-next-line
    }, [])

    if (user) return (
    <div className='root' style={{minHeight: '75vh', background: 'none'}}>
        {showMsg && <div style={{position: 'fixed', bottom: '16px', right: '16px', minWidth: '200px', background: '#282828AA', border: '1px solid #484239', borderRadius: '8px', padding: '16px', zIndex: 80, display: 'flex', alignItems: 'center', gap: '16px'}}>
            <button className='change-banner-btn' style={{right: 0}} onClick={() => setShowMsg(false)}> 
                ✖
            </button>
            <img alt='' style={{width: '64px'}} src={require('../assets/loading.gif')}/>
            <p>Uploading image...</p>
        </div>}
        <div className='app'>
            <h2 style={{fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px', marginBottom: 0}}>⚙️ DASHBOARD</h2>
            <div style={{display: 'flex', position: 'relative'}}>
                <div style={{backgroundColor: '#0f0f0f', position:'absolute', top: 0, right: 0, width: '100%', aspectRatio: '5/1', zIndex: -1, color: 'black', overflow: 'hidden', display: 'flex', justifyContent: 'flex-end'}}>
                    <img
                        alt=''
                        src={user.bannerurl === '' ? require('../assets/default-banner.png') : user.bannerurl}
                        className='inventory-info--banner'
                    />
                </div>
                <label htmlFor="banner" className='change-banner-btn' style={{left: 0}} >
                    Change banner 
                    <img alt='' src={require('../assets/icons/picture-icon.png')} style={{height:'16px', marginLeft: '4px'}}/>
                </label>
                <input id="banner" type="file" style={{display: 'none'}} accept="image/png, image/jpeg" onChange={btnLoading ? () => {} : e => handleChangeBanner(e)}/>
                <button className='change-banner-btn' style={{right: 0}} onClick={(btnLoading || user.bannerurl === '') ? () => {} : () => handleDelBanner()}> 
                    Remove ✖
                </button>
                
                <div style={{display: 'flex', flexDirection: 'column', flex: 1, padding: '110px 32px 32px 0px', gap: '8px', boxSizing: 'border-box', alignItems: 'flex-start',}}>
                    <div style={{position: 'relative', width: '250px', height: '250px', borderRadius: '9999px', cursor: 'pointer', alignSelf: 'center'}}>
                        <img
                            alt = ''
                            src={user.pfpurl === '' ? require('../assets/pfp/no-texture.png') : user.pfpurl}
                            style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '999px'}}
                            onClick={btnLoading ? () => {} : () => setPfpUpload(old => !old)}
                        />
                        {pfpUpload &&
                            <div className='pfp-upload' onBlur={() => setPfpUpload(false)}>
                                <label htmlFor="pfp">
                                    Upload Image
                                </label>
                                <input id="pfp" type="file" style={{display: 'none'}} onChange={e => handleChangePfp(e)} accept="image/png, image/jpeg" />
                                <button onClick={user.pfpurl === '' ? () => {} : () => handleDelPfp()}>Delete Image</button>
                            </div>
                        }
                    </div>
                    <h1 style={{margin: '16px 0px 0px 0px'}}>{user.displayName}</h1>
                    <p style={{margin: '0', fontSize: '18px', color: '#848484'}}>@{user.username}</p>
                    <p style={{margin: '0px 0px 16px 0px', fontSize: '10px', color: '#848484'}}>UID: {user.uidString}</p>
                    <Link to={`/uid/${user.uidString}`} className='default-btn' style={{width: '100%', marginTop: '8px', textDecoration: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box'}}>View Profile</Link>
                    <button className='default-btn' onClick={() => setShowLogout(true)} style={{width: '100%'}}>Logout</button>
                    {showLogout &&
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', backgroundColor: '#d63a3a44', border: '1px solid #d63a3a', borderRadius: '8px', padding: '8px', boxSizing: 'border-box'}}>
                            <p style={{margin: 0}}>Are you sure you want to log out?</p>
                            <div style={{display: 'flex', justifyContent: 'center', gap: '8px', marginTop: '8px'}}>
                                <button onClick={() => handleLogout()} style={{color: 'white', borderRadius: '6px', padding: '4px 16px 4px 16px', border: '1px solid #477ead', backgroundColor: '#2f516e', cursor: 'pointer'}}>Confirm</button>
                                <button onClick={() => setShowLogout(false)} style={{color: 'white', borderRadius: '6px', padding: '4px 16px 4px 16px', border: '1px solid #8a8a8a', backgroundColor: '#545454', cursor: 'pointer'}}>Cancel</button>
                            </div>
                        </div>
                    }
                </div>
                <div style={{flex: 2, padding: '244px 0px 32px 32px', boxSizing: 'border-box'}}>
                    <div className='main-container' style={{flexDirection: 'column'}}>
                        <h3 style={{marginTop: '8px'}}>Customization</h3>
                        <div style={{display: 'flex', flexDirection: 'column', margin: '8px 0px 8px 0px'}}>
                            <b>Vanity URL </b>
                            <div>
                                <input className='substat-input' style={{minWidth: '200px', marginRight: '8px'}} value={vanityurl} onChange={e => setVanityurl(e.target.value)}></input>
                                {vanityurl !== initialVanityurl && 
                                    <button onClick={btnLoading ? () => {} : () => {handleChangeVanity()}} style={{pointerEvents: btnLoading ? 'none':'', color: 'white', borderRadius: '6px', padding: '4px 16px 4px 16px', border: '1px solid #477ead', backgroundColor: '#2f516e', cursor: 'pointer'}}>Change</button>
                                }
                            </div>
                            <small className='fade-text' style={{width: '300px'}}>scoremyrelic.com/u/{vanityurl}</small>
                        </div>
                        
                        <div style={{display: 'flex', flexDirection: 'column', margin: '8px 0px 8px 0px'}}>
                            <button 
                                className={(lockCharPanel ? "dropup-arrow":"dropdown-arrow") + ' clear-button'}
                                style={{display: 'flex', alignItems:'center', fontWeight: 800}}
                                onClick={() => setLockCharPanel(old => !old)}
                            >Save character builds</button>
                            {lockCharPanel && <small>Saving a character ensures that its build remains permanently saved to your profile and will not reset or change, even when your profile is refreshed.</small>}
                            {lockCharPanel && <div style={{display: 'flex', flexWrap: 'wrap', marginTop: 8}}>
                                {chars.map(curr => {
                                    return (
                                        <div style={{width: 64, height: 90, margin: 4, display:'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <img alt='Head icon' src={(() => {
                                                try {
                                                    return require(`../assets/pfp/20${curr.id}.png`)
                                                }catch (e) {
                                                    return require(`../assets/pfp/no-texture.png`)
                                                }
                                            })()} style={{marginBottom: 8, width: '100%', background: curr.rarity === 5 ? 'linear-gradient(to bottom, #74443F, #CBA368)' : 'linear-gradient(to bottom, #5F5682, #B085C8)'}}/> 
                                            <input id={curr.id} type='checkbox' className='hidden-checkbox' checked={lockCharArray.includes(curr.id)}
                                            onChange={() => {
                                                setChangedProfile(true)
                                                if(!lockCharArray.includes(curr.id)){
                                                    setLockCharArray(old => [...old, curr.id])
                                                }else{
                                                    setLockCharArray(old => {
                                                        const newthing = [...old]
                                                        newthing[lockCharArray.findIndex(currInd => currInd === curr.id)] = newthing[newthing.length - 1]
                                                        newthing.pop()
                                                        return newthing
                                                    })
                                                }
                                            } }></input>
                                            <label htmlFor={curr.id} className='default-checkbox'></label>
                                        </div>
                                    )
                                })}
                            </div>}
                            <button
                                onClick={(btnLoading || !changedProfile) ? () => {} : () => {handleChangeLockedChars()}}
                                style={{pointerEvents: (btnLoading || !changedProfile) ? 'none':'', color: 'white', borderRadius: '6px', padding: '4px 16px 4px 16px', border: `1px solid ${(btnLoading || !changedProfile) ? 'lightgrey' : '#477ead'}`, backgroundColor: (btnLoading || !changedProfile) ? 'grey' : '#2f516e', cursor: 'pointer', width: 200, marginTop: 16}}
                            >Save changes</button>
                        </div>

                        {/* <div style={{display: 'flex', flexDirection: 'column', margin: '8px 0px 8px 0px'}}>
                            <b>{lockCharArray}</b>
                        </div> */}
                    </div>
                    <div className='main-container' style={{flexDirection: 'column'}}>
                        <h3 style={{marginTop: '8px'}}>Password Reset</h3>
                        <b>Current Password</b>
                        <input type='password' className='substat-input' style={{minWidth: '200px'}} value={pwdObj.currPassword} onChange={e => setPwdObj(old => {return ({...old, currPassword: e.target.value})})}></input>
                        <b style={{marginTop: '16px'}}>New Password</b>
                        <input type='password' className='substat-input' style={{minWidth: '200px'}} value={pwdObj.newPassword} onChange={e => setPwdObj(old => {return ({...old, newPassword: e.target.value})})}></input>
                        <b style={{marginTop: '16px'}}>Confirm</b>
                        <input type='password' className='substat-input' style={{minWidth: '200px'}} value={pwdConfirm} onChange={e => setPwdConfirm(e.target.value)}></input>
                        <button onClick={() => handleChangePwd()} style={{marginTop: '16px', pointerEvents: btnLoading ? 'none':'', color: 'white', borderRadius: '6px', padding: '4px 16px 4px 16px', border: '1px solid #477ead', backgroundColor: '#2f516e', cursor: 'pointer', width: '200px'}}>Change Password</button>
                    </div>
                    <div className='main-container' style={{flexDirection: 'column'}}>
                        <h3 style={{marginTop: '8px'}}>Danger Zone</h3>
                        <b>Account deletion</b>
                        <button onClick={() => setAccountDelPanel(true)} style={{marginTop: '16px', pointerEvents: btnLoading ? 'none':'', color: 'white', borderRadius: '6px', padding: '4px 16px 4px 16px', border: '1px solid #ff6363', backgroundColor: '#f54242', cursor: 'pointer', width: '200px'}}>Permanently delete account</button>
                    </div>
                    {accountDelPanel &&
                    <div style={{position: 'fixed', width: '100%', height: '100%', backgroundColor: '#000000AA', top: 0, left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backdropFilter: 'blur(4px)', WebkitBackdropFilter: 'blur(4px)'}}>
                        <div className='main-container' style={{flexDirection: 'column', alignItems: 'flex-start', maxWidth: '420px'}}>
                            <h3 style={{marginTop: '8px', alignSelf: 'center'}}>Confirm account deletion</h3>

                            <p style={{marginBottom: '32px'}}>Deleting your account will:
                                <ul>
                                    <li>Remove your all your account data forever</li>
                                    <li>Allow your UID, username, vanity URL to be claimed again</li>
                                </ul>
                                To confirm account deletion, type: <i>"{`Permanently delete account ${user.username}`}"</i> in the confirmation input.
                            </p>
                            <b>Current Password</b>
                            <input type='password' className='substat-input' style={{minWidth: '300px'}} value={accountDelPwd} onChange={e => setAccountDelPwd(e.target.value)}></input>
                            <b style={{marginTop: '16px'}}>Confirmation</b>
                            <input className='substat-input' style={{minWidth: '300px'}} value={accountDelConf} onChange={e => setAccountDelConf(e.target.value)} ></input>
                            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <button onClick={() => {setAccountDelPanel(false); setAccountDelConf(''); setAccountDelPwd('')}} style={{marginTop: '16px', pointerEvents: btnLoading ? 'none':'', color: 'white', borderRadius: '6px', padding: '4px 16px 4px 16px', border: '1px solid #477ead', backgroundColor: '#2f516e', cursor: 'pointer', width: '200px'}}>I change my mind</button>
                                <button onClick={() => handleDelAccount(accountDelConf)} style={{marginTop: '16px', pointerEvents: btnLoading ? 'none':'', color: 'white', borderRadius: '6px', padding: '4px 16px 4px 16px', border: '1px solid #ff6363', backgroundColor: '#f54242', cursor: 'pointer', width: '200px'}}>Delete account forever</button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    )
    
    return(
        <div style={{height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '64px'}}>
            
        </div>
    )
}

export default Dashboard