import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import dbService from '../services/db-service'
import ManualInputForm from '../comps/manual-input/ManualInputForm'
import InfoPanel from '../comps/InfoPanel'
import MethodsPanel from '../comps/MethodsPanel';
import UserSearchForm from '../comps/uid-input/UserSearchForm';

const App = ({mobileSize, viewportWidth}) => {
  const [appMethod, setAppMethod] = useState(0)
  const [sets, setSets] = useState([])
  const [chars, setChars] = useState([])

  //uid input states
  const [inputSearch, setInputSearch] = useState('')
  const [uidList, setUidList] = useState(null)
  const [showSearch, setShowSearch] = useState(false)
  const [showInv, setShowInv] = useState(false)
  const [currInventory, setCurrInventory] = useState({relicList: []})
  
  //manual input states
  const [relic, setRelic] = useState({
    set: ['pdodw', 0],
    type: 'head',
    mainstat: 'hp-flat',
    sub1: ['crate', 8.7],
    sub2: ['cdmg', 17.4],
    sub3: ['dead', 0],
    sub4: ['dead', 0],
  })
  const [scoredRelic, setScoredRelic] = useState(null)
  const [basis, setBasis] = useState('character')
  const [charsetType, setCharsetType] = useState('1')
  const [charWeightmap, setCharWeightmap] = useState({
      "display": "No Criteria",
      'crate': 1, 'cdmg': 1, 'spd': 1, 'atk-perc': 1, 'atk-flat': 1, 'hp-perc': 1,
      'hp-flat': 1, 'def-perc': 1, 'def-flat': 1, 'break': 1, 'ehr': 1, 'res': 1,
      'mainStats': {
      'ignore': true,
      'sets': [], 'body': [], 'feet': [],
      'sphere': [], 'rope': [],
      }
  })
  const [customWeightMap, setCustomWeightMap] = useState({
      'crate': 1, 'cdmg': 1, 'spd': 1, 'atk-perc': 1, 'atk-flat': 1, 'hp-perc': 1,
      'hp-flat': 1, 'def-perc': 1, 'def-flat': 1, 'break': 1, 'ehr': 1, 'res': 1,
      'mainStats': {
      'ignore': true,
      'sets': [], 'body': [], 'feet': [],
      'sphere': [], 'rope': [],
      }
  })

  const getSetsChars = debounce(async () => {
    const version = await dbService.getVersion()
    if (version){
      setSets(JSON.parse(localStorage.getItem('dbSets')))
      setChars(JSON.parse(localStorage.getItem('dbCharacters')))
    }
    else{
      setSets(await dbService.getSets())
      setChars(await dbService.getChars())
    }
    
  }, 300)
  
  useEffect(() => {
      getSetsChars()
      window.scrollTo(0, 0)
      document.title = 'Relic Scorer | scoremyrelic'
      // eslint-disable-next-line
  }, [])

  return (
    <div className="root" style={{background: 'none'}}>
      <div className='app'>
        <h2 style={{fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px'}}>📊 HONKAI: STAR RAIL RELIC SCORER</h2>
        
        <MethodsPanel appMethod={appMethod} setAppMethod={setAppMethod}/>
        
        {appMethod === 0 && <UserSearchForm
          scoredRelic={scoredRelic}
          currInventory={currInventory}
          setCurrInventory={setCurrInventory}
          viewportWidth={viewportWidth}
          mobileSize={mobileSize}
          inputSearch={inputSearch}
          setInputSearch={setInputSearch}
          uidList={uidList}
          setUidList={setUidList}
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          showInv={showInv}
          setShowInv={setShowInv}
        />}
        {appMethod === 1 && <ManualInputForm
          sets={sets}
          chars={chars}
          relic={relic}
          setRelic={setRelic}
          scoredRelic={scoredRelic}
          setScoredRelic={setScoredRelic}
          basis={basis}
          setBasis={setBasis}
          charsetType={charsetType}
          setCharsetType={setCharsetType}
          charWeightmap={charWeightmap}
          setCharWeightmap={setCharWeightmap}
          customWeightMap={customWeightMap}
          setCustomWeightMap={setCustomWeightMap}
        />}
        
        <InfoPanel />
      </div>
    </div>
  )
}

export default App;