import axios from 'axios'
const baseUrl=`${process.env.REACT_APP_API_URL}/api/internalUser`
// const baseUrl='http://localhost:3001/api/internalUser'

const getUser = async (token) => {
    //returns user obj given auth token
    const response = await axios.get(`${baseUrl}/info`, 
    {
        headers: {Authorization: `Bearer ${token}`}
    })
    return response.data
}

const getUidFromVanity = async (vanityParam) => {
    const response = await axios.get(`${baseUrl}/vanity/${vanityParam}`)
    return response.data
}

const changeVanity = async (newVanityString, token) => {
    const response = await axios.post(`${baseUrl}/vanity/userTransaction`, 
    {
        newVanityString: newVanityString
    },
    {
        headers: {Authorization: `Bearer ${token}`}
    })
    return response.data 
}

const changeLockedChars = async (lockedCharsIn, token) => {
    const response = await axios.post(`${baseUrl}/lockedChars/userTransaction`, 
    {
        lockedCharsIn: lockedCharsIn
    },
    {
        headers: {Authorization: `Bearer ${token}`}
    })
    return response.data 
}

const changePfp = async (formData, token) => {
    const response = await axios.post(`${baseUrl}/newpfp/userTransaction`, formData,
    {
        headers: {Authorization: `Bearer ${token}`}
    })
    return response.data 
}

const delPfp = async (token) => {
    const response = await axios.post(`${baseUrl}/delpfp/userTransaction`, {},
    {
        headers: {Authorization: `Bearer ${token}`}
    })
    return response.data 
}

const changeBanner = async (formData, token) => {
    const response = await axios.post(`${baseUrl}/newbanner/userTransaction`, formData,
    {
        headers: {Authorization: `Bearer ${token}`}
    })
    return response.data 
}

const delBanner = async (token) => {
    const response = await axios.post(`${baseUrl}/delbanner/userTransaction`, {},
    {
        headers: {Authorization: `Bearer ${token}`}
    })
    return response.data 
}

const resetPassword = async (pwdObj, token) => {
    const response = await axios.post(`${baseUrl}/newPassword/userTransaction`, pwdObj,
    {
        headers: {Authorization: `Bearer ${token}`}
    })
    return response.data
}

const delAccount = async (pwd, token) => {
    const response = await axios.post(`${baseUrl}/deleteAccount/userTransaction`, {currPassword: pwd},
    {
        headers: {Authorization: `Bearer ${token}`}
    })
    return response.data
}

const inUserService = {getUser, getUidFromVanity, changeVanity, changeLockedChars, changePfp, delPfp, changeBanner, delBanner, resetPassword, delAccount}
export default inUserService