import React from 'react'
import userDbService from '../../services/userdb-service'
import ScoredInventory from '../inv-display/ScoredInventory'
import SearchedUser from './SearchedUser'
import { useState } from 'react'
import UidSearchBar from './UidSearchBar'

export default function UserSearchForm(
  {
    currInventory, setCurrInventory,invViewing, setInvViewing, viewportWidth,
    mobileSize, inputSearch, setInputSearch, uidList, setUidList, showSearch,
    setShowSearch, showInv, setShowInv
  }){

  const [showLoading, setShowLoading] = useState(false)

  const formHandler = {
    searchUser: async (e) => {
      e.preventDefault()
      if (inputSearch === '') {return} 
      setShowLoading(true)
      setShowInv(false)
      setShowSearch(false)
      try {
        const searchResult = await userDbService.searchUser(inputSearch)
        setUidList(searchResult)
      }
      catch(e) {
        alert(e.response.data.error)
      }
      setShowSearch(true)
      setInputSearch('')
      setShowLoading(false)
    },
    viewUser: async (givenUid) => {
      const uidInfo = await userDbService.getUser(givenUid)
      setShowSearch(false)
      setShowInv(true)
      setCurrInventory(uidInfo)
    },
    backToSearch: () => {
      setShowSearch(true)
      setShowInv(false)
    }
  }

  const renderUidList = uidList !== null 
  ? uidList.map((curr, index) => {
    return(
      <SearchedUser user={curr} formHandler={formHandler} viewportWidth={viewportWidth} mobileSize={mobileSize} key={index}/>
    )
  })
  : []

  return (
    <div className="main-container">
      <div className="form-panel">
        <h3 style={{margin: 0}}>Hoyoverse UID & Name Search</h3>
        <small style={{marginBottom: '16px'}}>By searching for the first time, you will index the data in your <b>active in-game character showcase</b> to our database.</small>
        <UidSearchBar formHandler={formHandler} inputSearch={inputSearch} setInputSearch={setInputSearch}/>
        {showInv === true && <p onClick={() => formHandler.backToSearch()} style={{cursor: 'pointer', textDecoration: 'underline', margin: '32px 0px 4px 0px', width: '110px'}}>⤺ Back to search</p>}
        {showLoading === true && 
          <div style={{alignSelf: 'center', marginTop: '16px'}}>
            <img src={require('../../assets/loading.gif')} style={{width: '64px'}} alt="loading..."/><br />
            <span>Loading...</span>
          </div>
        }
        {uidList !== null && showSearch === true &&
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <h3 style={{fontWeight: 300, margin: '16px 0px 0px 0px'}}>Search Results ({uidList.length}): </h3>
            <small style={{marginBottom: '8px'}}>Don't see your account? Be more specific or enter your in-game UID.</small>
            {renderUidList}
          </div>
        }
        
        {showInv === true && <ScoredInventory
          currInventory={currInventory}
          invViewing={invViewing}
          setInvViewing={setInvViewing}
          viewportWidth={viewportWidth}
          mobileSize={mobileSize}
        />}
      </div>
    </div>
    
  )
}