import React from 'react'
import RVDisplay from '../RVDisplay'
import { findColor, findRank } from '../../utils/scoreStyle'

function InvViewing({invViewing}) {
    if (invViewing === null) {
        return (
            <div className='inv-viewing'>

            </div>
        )
    }

    const renderSubs = Object.keys(invViewing.subs)
    .map((curr => {
        return invViewing.subs[curr]
    }))
    .map((curr, index) => {
        const objForRVDisplay = {
            display: curr[0],
            value: curr[1],
            rollCombo: curr[2],
            weight: curr[3]
        }
        return <RVDisplay key={`rvdisplay${index}`} sub={objForRVDisplay} index={index}/>
    })
    const renderPenalties = invViewing.penalties.map((curr, index) => {
        return <p style={{margin: '4px', color: curr[0].includes('+') ? '#42f55a' : '#f54242', fontSize: '12px'}} key={index}>{curr[0]}</p>
    })

    return (
        <div className='inv-viewing' style={{border: `3px solid ${findColor(invViewing.score)}99`, boxShadow: `0 0 20px 0px ${findColor(invViewing.score)}77`}} >
            <div style={{display: 'flex'}}> 
                <img alt='Item icon' src={(() => {
                    try {
                        return require(`../../assets/relics/${invViewing.set[0]}-${invViewing.type}.png`);
                    }catch (e) {
                        return require(`../../assets/relics/no-texture.png`);
                    }
                })()} style={{width: '100px', height: '100px', margin: '12px 12px 0px 12px'}}/>
                <div>
                    <p>{invViewing.mainstatDisplay}</p>
                    <div style={{display: 'flex'}}>
                        <div style={{marginRight: '20px'}}>
                            <p style={{margin: 0, fontSize: '14px'}}>Score: </p>
                            <b style={{margin: 0, fontSize: '22px', color: findColor(invViewing.score)}}>{invViewing.score.toFixed(1)} </b>
                        </div>
                        <div>
                            <p style={{margin: 0, fontSize: '14px'}}>Rank: </p> 
                            <b style={{margin: 0, fontSize: '22px', color: findColor(invViewing.score)}}>{findRank(invViewing.score)} </b>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{width: '100%'}}/>
            <span style={{fontSize: '18px', color: `${findColor(invViewing.score) }`}}>{invViewing.display}</span>  
            <p style={{color: '#949494', fontSize: '12px'}}>
            Score basis: {invViewing.basis} <br />
            Eff. stats: {invViewing.effStats} <br />
            Total rolls: {invViewing.rolls} <br />
            Gear type: <span style={{textTransform: 'capitalize'}}>{invViewing.type}</span>
            </p>
            {renderSubs}
            {renderPenalties}
        </div>
    )
}

export default InvViewing