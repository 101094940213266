import React from 'react'
import { getSignature } from '../../utils/signatureParse'
import userDbService from '../../services/userdb-service'
import { useState } from 'react'
import RefreshCooldown from './RefreshCooldown'
import { timeAgo } from '../../utils/timeAgo'

function MobileProfileBanner({user, profileVersion}) {

  const signature = getSignature(user.signature)
  const [loading, setLoading] = useState(false)
  const handleUserRefresh = async () => {
    setLoading(true)
    try{
      await userDbService.refreshUser(user.uid)
      window.location.reload()
    }catch (e){
      alert('Could not refresh profile. Either an internal error occurred, or the game is under maintenance right now.')
      setLoading(false)
    }
  }

  const renderBadges = user.badges.map((curr, index) => {
    return(
      <div key={`userbadgemobile${index}`} style={{textAlign: 'center', lineHeight: '9px', margin: '0px 8px 0px 0px', backgroundColor: curr.color, display: 'inline', borderRadius: '99px', padding: '0px 8px 3px 8px'}}>
        <span style={{fontWeight: 600, fontSize: '9px', textTransform: 'uppercase'}}>{curr.text}</span>
      </div>
    )
  })

  return (
    <>
    <div className="profile-panel" style={{padding: 0, backgroundColor: '#0F0F0F'}}>
      <div style={{display: 'flex', position: 'relative'}}>
        
        <div style={{display: 'flex', flex: 1, zIndex: 2, margin: '8px'}}>
          <div style={{position: 'relative', height: '86px', aspectRatio: '1/1', borderRadius: '999px', alignSelf: 'center', margin: '0px 8px 0px 8px', border: '1px solid #474239', boxSizing: 'border-box'}}>
            <img alt='' src={(() => {
              try {
                if (!user.user || user.user.pfpurl === '') return require(`../../assets/pfp/${user.pfp}.png`)
                return user.user.pfpurl
              } catch (e) {
                return require(`../../assets/pfp/no-texture.png`)
              }
            })()} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '999px'}}/>
          </div>
          
          <div style={{display: 'flex', flexDirection:'column', width: '100%', height: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <h3 style={{margin: '8px 0px 0px 0px', color: 'white', fontSize: '20px', fontWeight: 300}}>{user.displayName}</h3>
            <i style={{margin: '0px 0px 0px 0px', fontSize: '11px', color: signature.color}}>"{signature.text}"</i>
            <small style={{fontSize: '9px', justifySelf: 'center'}}>UID: {user.uid}</small>
            <div style={{display: 'inline-block', height: '32px', marginTop: '4px'}}>
              {renderBadges}
            </div>
          </div>

        </div>

        <img alt='' className="inventory-info--banner" src={(() => {
            try {
              if (!user.user || user.user.bannerurl === '') return require(`../../assets/default-banner.png`)
              return user.user.bannerurl
            } catch (e) {
              return require(`../../assets/default-banner.png`)
            }
          })()}/>
      </div>
    </div>

    {profileVersion !== null && (!user.version || user.version !== profileVersion) && <p style={{fontSize: '10px', pointerEvents: 'none', zIndex: 10}}>⚠️ This profile is outdated (out of sync after major system changes). Refresh profile to re-sync this account.</p>}

    <div className="profile-panel" style={{padding: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      
      <div>
        <p style={{margin: 0, fontSize: '13px', textShadow: '1px 1px 2px black' }}>Maturity: <b>Lvl {user.level}</b><span style={{fontSize: '9px'}}>/70</span></p>
        <p style={{margin: 0, fontSize: '13px', textShadow: '1px 1px 2px black' }}>Characters: <b>{user.totalChars ? user.totalChars : 0}</b><span style={{fontSize: '9px'}}>/60</span></p>
        <p style={{margin: '0px', fontSize: '9px', textShadow: '1px 1px 2px black' }}>Last Updated: {timeAgo(user.lastUpdated)}</p>
      </div>
      <div style={{width: '150px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <RefreshCooldown handleUserRefresh={() => handleUserRefresh()} lastUpdated={user.lastUpdated} loading={loading}/>
      </div>
      
      
    </div>
    </>
  )
}

export default MobileProfileBanner