import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

function Contribute() {

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'Contribute | scoremyrelic'
  }, [])

  return (
    <div className='root' style={{minHeight: '75vh', background: 'none'}}>
        <div className='app'>
            <h2 style={{fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px'}}>🎁 SUPPORT US</h2>
            <div className='main-container' style={{position: 'relative'}}>
              <div className='form-panel'>
                <h3 style={{margin: 0, zIndex: 1}}>Buy me a coffee :)</h3>
                <small style={{zIndex: 1}}>No sign-ins required. Your support is will be much appreciated! 😊</small>
                <Link to="https://ko-fi.com/gdonlol" target="_blank" style={{width: '250px', display: 'flex',margin: '16px 0px 0px 0px', padding: 0, background: 'none', border: 'none', cursor: 'pointer'}}>
                  <img alt='' style={{width:'100%', zIndex: 1}} src={require('../assets/kofi_button_red.png')}/>
                </Link>
              </div>
              <img alt='' style={{position: 'absolute', zIndex: 0, top: 0, right: 0, height: '100%', opacity: '50%'}} src={require('../assets/coffee-drink.png')}/>
            </div>

            <div className='main-container'>
              <div className='form-panel'>
                <h3 style={{margin: 0}}>Upcoming features:</h3>
                <small>Your support helps us bring new features to you faster!</small>
                <ul>
                  <li><b>Niche weightmaps</b>: We have noticed that some niche builds require atypical substats. Account owners can customize what weightmaps to apply to their characters when a calculation refresh is requested.</li>
                  <li><b>Team scoring and leaderboards</b>: Score damage output using certain team compositions.</li>
                  <li><b>Profile page customization</b>: We want to offer more customization options for our users (all of it course, will be 100% free).</li>
                  <li><b>Community meta propositions</b>: Members of our community can propose changes to the current meta which can be upvoted by other community members.</li>
                  <li><b>Relic screenshot scanner</b>: OCR to read screenshots and translate to relic stats for faster relic scoring.</li>
                </ul>

                {/* <h3 style={{margin: '16px 0px 0px 0px'}}>Discord Server:</h3>
                <small>Join our discord to stay updated on the website!</small>
                <Link target='_blank”'to='https://discord.gg/9WbfzwyF9T'>https://discord.gg/9WbfzwyF9T</Link> */}

                <h3 style={{margin: '16px 0px 0px 0px'}}>Feedback form:</h3>
                <small>Your feedback is important to us. Help us improve your experience!</small>
                <Link target='_blank”'to='https://docs.google.com/forms/d/e/1FAIpQLSesJ8TrD1JWK72VbCAmQYgI_w7-SkOdpBWe1o8VrPj44R6HUA/viewform?usp=sf_link'>Google form link</Link>
                
              </div>
            </div>
        </div>
    </div>
  )
}

export default Contribute