import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

function Contact() {

useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'Contact Us | scoremyrelic'
    }, [])

  return (
    <div className='root' style={{minHeight: '75vh', background: 'none'}}>
        <div className='app'>
            <h2 style={{fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px'}}>📞 CONTACT</h2>
            <div className='main-container'>
              <div className='form-panel'>
                <h3 style={{margin: 0}}>Email us:</h3>
                <p style={{marginTop: 0}}>scoremyrelic@gmail.com</p>
                <p style={{marginBottom: 0}}>Yeah that's the only contact, at least for now. 😅</p>
                <p style={{margin: 0}}>If you want to provide feedback faster, you may do so <Link target='_blank”'to='https://docs.google.com/forms/d/e/1FAIpQLSesJ8TrD1JWK72VbCAmQYgI_w7-SkOdpBWe1o8VrPj44R6HUA/viewform?usp=sf_link'>here.</Link></p>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Contact