import axios from 'axios'
const baseUrl=`${process.env.REACT_APP_API_URL}/api/auth`
// const baseUrl='http://localhost:3001/api/auth'

const submitNew = async (payloadObj) => {
    //this gateway should return auth key on success
    const response = await axios.post(`${baseUrl}/signup/new/authTransaction`, payloadObj)
    return response.data
}

const ownershipCheck = async (payloadObj) => {
    //this gateway should return auth key on success
    const response = await axios.post(`${baseUrl}/signup/ownership/authTransaction`, {username: payloadObj.username, uid: payloadObj.uid})
    return response.data
}

const login = async (payloadObj) => {
    //this gateway should return auth key on success
    const response = await axios.post(`${baseUrl}/login/authTransaction`, payloadObj)
    return response.data
}

const authService = {submitNew, ownershipCheck, login}
export default authService