import React from 'react'
import { InlineMath } from 'react-katex';

function InfoPanel() {
  return (
    <div className="info-panel" style={{padding: '32px'}}>
        <h2 style={{marginTop: '0px'}}>Relic Score in Honkai: Star Rail Explained</h2>
        <h3>What is a good score?</h3>
        <p>The general rule of thumb:</p>
        <ul style={{marginBottom: '16px'}}>
          <li> <span style={{color: '#87e053'}}>20+</span> RS is a useable piece</li>
          <li> <span style={{color: '#ccdb44'}}>30+</span> RS is a good score (common "stopping point" for most players)</li>
          <li> <span style={{color: '#db9544'}}>40+</span> RS is an insane score (above average)</li>
          <li> <span style={{color: '#db5844'}}>50+</span> RS is a near flawless piece (very few or none will exist on your account)</li>
          <li> <span style={{color: '#c93434'}}>58.3</span> RS is the highest achievable score (max roll = 6.48 RS * 9 rolls = 58.32)</li>
        </ul>
        <p style={{marginTop: '0px'}}>Note: <b>The max score is lower for characters who have less than 4 stats with a weight of 1</b>. This means that the character maximally benefits from less than 4 distinct stat types, thus making them harder to build (remember a relic holds up to 4 subs). Some characters are just like that 😔. <b>Although generally, hitting 50+ RS is possible for all characters.</b></p>
        <h3>What exactly is "Relic Score"?</h3>
        <p>Relic score is essentially just a measurement of how valuable your gear is. How?</p>
        <ul>
          <li>evaluates all 4 substats</li>
          <li>checks how much value you got out of each stat</li>
          <li>adds them together</li>
          <li>evaluates main stat and set and applies penalties/bonuses</li>
        </ul>
        <p>Every time you upgrade a relics stats, it can be either a high, mid, or low roll. We want to figure out how close you were to getting all high rolls, and assign a score to that.</p>
        <p>The formula also applies weights on each stat, which vary depending on the benefit they bring to a character. For example, DEF% adds little to no value to most dps characters, so it is typically weighted as 0 in their score calculations. Conversely, it holds significance for many support characters, so it is often weighted as 1 in their score calculations.</p>
        <h3>The formula</h3>
        <p>The relic score (RS) formula is a very simple formula takes inspiration from Epic 7's gear score (GS) formula, as well as the crit value (CV) formula from Genshin Impact. Simply put, it's just a linear combination of the substats of relic <InlineMath math="R" />:</p>
        <InlineMath math="RS = R_{c.dmg\%} + R_{break\%} + 2R_{c.rate\%} + \frac{5}{2} R_{spd} + \frac{3}{2} R_{atk\%} + \frac{3}{2} R_{hp\%} + \frac{3}{2} R_{eff. hit\%} + \frac{3}{2} R_{eff. res\%} + \frac{6}{5} R_{def\%} + \frac{3}{2}(100(\frac{R_{atk}}{1149})) + \frac{3}{2}(100(\frac{R_{hp}}{2271})) + \frac{6}{5}(100(\frac{R_{def}}{988}))" />
        <h3>The coefficients</h3>
        <p>The possible CRIT DMG & Break Effect% values are the highest in the game (<InlineMath math="5.184\%, 5.832\%, 6.48\%" />) , so it is used as the <b>baseline unit of relic score.</b> Every stat other than CRIT DMG and Break Effect% uses a magic number coefficient to convert it to the baseline unit.</p>
        <p>CRIT RATE can be: <InlineMath math="2.592\%,	2.916\%,	3.24\%"/> Focusing on the max roll <InlineMath math="3.24\%"/> for example, we see that <InlineMath math="3.24 * 2 = 6.48"/>... 6.48 being the max CRIT DMG roll (as seen above). So the coefficient for CRIT RATE is 2. This is the same as Genshin's CV formula.</p>
        <p>HP can be: <InlineMath math="3.456\%, 3.888\%, 4.32\%"/> Focusing on the min roll <InlineMath math="3.456\%"/> for example, we see that <InlineMath math="3.456 * 1.5 = 5.184"/>... 5.184 being the min CRIT DMG roll (as seen above). So the coefficient for HP% is 1.5 or <InlineMath math="\frac{3}{2}" />.</p>
        
        <table className='info-table'>
          <thead>
            <tr>
              <th>Stat</th>
              <th>Multiplier</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CRIT Rate</td>
              <td>2</td>
            </tr>
            <tr>
              <td>SPD</td>
              <td>2.5</td>
            </tr>
            
            <tr>
              <td>Atk% / HP% / EHR / RES</td>
              <td>1.5</td>
            </tr>
            <tr>
              <td>Def%</td>
              <td>1.2</td>
            </tr>
          </tbody>
        </table>
        
        <h3>The flat stats </h3>
        <p>In order to add flat stats the the relic score, we have to first convert them to the value of a percentage stat, then convert it to the baseline unit. Flat stats are additive, and different character builds have different base stats, so the percentage equivalent value of a flat stat varies between character and lightcone combinations.</p>
        <p>Base stat formula: <InlineMath math="Base = HpCharacter + HpLightcone" /></p>
        <p>Since the philosophy of relic score is to minimize whale influence, we use the median base stats of the current roster of characters and 5 star lightcones at max level (as of version 2.0):</p>
        <ul>
        <li>Median base atk: 620 + 529 = 1149</li>
        <li>Median base hp: 1160 + 1111 = 2271</li>
        <li>Median base def: 492 + 496 = 988</li>
        </ul>
        <p>To convert it to a percentage, <b>divide the stat by the median base stat and multiply by 100.</b> For those confused why it works:</p>
        <ol>
        <li>In-game formula for the stats ATK, HP, DEF is: <InlineMath math="Total = Base (1 + Percent) + Additive" /></li>
        <li>Using simple algebra, we can re-arrage the equation to: <InlineMath math="Total = Base (1 + Percent + \frac{Additive}{Base})" /></li>
        </ol>
        <p>Let's say Physical Main Character has a base HP of <InlineMath math="1203 + 1058 = 2261" /> (base + Moment of Victory lightcone). We have a FLAT HP low roll of <InlineMath math="33.87" />. Converting it to a percentage, we have: <InlineMath math="100(\frac{33.87}{2261}) = 1.49800973" />. When we divide by the median base stat (shown above) instead of Physical Main Character's base stat we get: <InlineMath math="100(\frac{33.87}{2271}) = 1.49141347" />. A very close result.</p>
        <p>Flat stats are then converted to the baseline unit as usual. We use the same magic numbers as seen above with percentage, as to not overvalue flat sats. A FLAT HP low roll results in about 2.23 RS, whereas a low roll HP% is 5.184 RS.</p>
        
        <h3>Main stats and sets </h3>
        <p>Since main stats and sets are crucial to a character's build, they are given penalties and bonuses. The penalties and bonuses are as follows:</p>
        <ul>
        <li>Unsuitable set: -10% RS</li>
        <li>Off-set piece: -15% RS</li>
        <li>Unwanted main stat: -50% RS</li>
        <li>Strong main stat: +5.832 * Weight of main stat bonus</li>
        </ul>
        <p>Unwanted main stats are punished heavily (-50%) due to their high importance in building characters. Unsuitable sets are not punished as hard as off-set pieces, since having "rainbow gear" gives zero set effects, whereas unsuitable sets can at least give some sort of effect. </p>
    </div>
  )
}

export default InfoPanel