import React from 'react'
import { invValueRank, invValueColor } from '../../utils/scoreStyle'
import { Link } from 'react-router-dom';

function MobileInvInfo({currInventory}) {
  return (
    <div className='inventory-info' style={{borderTopRightRadius: '48px'}}>
      <img alt="head icon" src={(() => {
        try {
          return require(`../../assets/pfp/${currInventory.pfp}.png`);
        } catch (e) {
          return require(`../../assets/pfp/no-texture.png`);
        }
      })()} style={{width: '75px', height: '75px', margin: '8px', borderRadius: '999px', border: '2px solid white'}} />
      <div style={{zIndex: 1}} >
          <Link to={`/uid/${currInventory.uid}`} style={{fontSize: '14px', margin: '4px', textShadow: '1px 1px 2px black', color: 'white'}}>{currInventory.displayName}</Link>
          <p style={{ margin: '4px', textShadow: '1px 1px 2px black'}}>Inventory Value: <b style={{color: invValueColor(currInventory.invScore)}}>{Math.floor(currInventory.invScore * 10)/10}</b> RS <br/><small>({invValueRank(currInventory.invScore)})</small></p>
      </div>
      <img className="inventory-info--bannerMobile" 
        src={require('../../assets/default-banner.png')}
        style={{}}
        alt= ""
      />
    </div>
  )
}

export default MobileInvInfo