import React from 'react'

function UidSearchBar({formHandler, inputSearch, setInputSearch}) {
  return (
    <form onSubmit={e => formHandler.searchUser(e)} style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #FFFFFF55', overflow: 'hidden', minHeight: '46px', margin: '16px 0px 16px 0px'}} >
        <input
        className="round-input"
        value={inputSearch}
        onChange={e => setInputSearch(e.target.value)}
        placeholder="Enter UID or username"
        style={{height: '48px', flex: 1, border: 'none', borderRadius: 0, margin: 0, backgroundColor: '#00000034'}}
        ></input>
        <button style={{display: 'flex', justifyContent: 'center', alignItems: 'center', aspectRatio: '1/1',width: '50px', height: '100%', color: 'white', backgroundColor: '#236DAD', border: 'none', cursor: 'pointer', fontFamily: 'Anuphan'}}>
            <img style={{height: '22px'}} alt='Start search' src={require('../../assets/icons/search-icon.png')}/>
        </button>
    </form>
  )
}

export default UidSearchBar