import React from 'react'

function SubstatForm({ relic, formHandler, subnum }) {
  return (
    <div >
        <select className='substat-dropdown' value={relic[`sub${subnum}`][0]} onChange={(e) => formHandler.changeSubstat(e, subnum)}>
            <option value='dead'>dead stat</option>
            <option value='cdmg'>CRIT DMG</option>
            <option value='crate'>CRIT RATE</option>
            <option value='spd'>SPD</option>
            <option value='atk-perc'>ATK%</option>
            <option value='atk-flat'>ATK</option>
            <option value='hp-perc'>HP%</option>
            <option value='hp-flat'>HP</option>
            <option value='def-perc'>DEF%</option>
            <option value='def-flat'>DEF</option>
            <option value='break'>Break Effect%</option>
            <option value='ehr'>Effect Hit Rate%</option>
            <option value='res'>Effect Res%</option>
        </select>
        <input 
          className='substat-input'
          type="text" 
          pattern="\d*\.?\d+$"
          value={relic[`sub${subnum}`][1]}
          maxLength="4"
          relictype='text'
          onChange={(e) => formHandler.changeSubvalue(e, subnum)}
          onInvalid={(e) => formHandler.invalidInput(e)}>
        </input>
        
        {!(['dead', 'spd', 'atk-flat', 'hp-flat', 'def-flat'].includes(relic[`sub${subnum}`][0])) && ' %'}
        {relic[`sub${subnum}`][0] === 'dead' && " lost rolls"}
    </div>
  )
}

export default SubstatForm