import React from 'react'

function UnitRSDisplay({ userArr }) {

    const headIconPath = userArr[0]
    const scoreForUser = userArr[1]
    const userRarity = userArr[2]

    if (headIconPath === "null") {
        return (
            <div className='unit-rsdisplay--empty'/> 
        )
    }

    return (
        <div className='unit-rsdisplay' style={userRarity === 5 
        ? {background: 'linear-gradient(to bottom, #74443F, #CBA368)'}
        : {background: 'linear-gradient(to bottom, #5F5682, #B085C8)'}}> 
            <img alt='' src={(() => {
                try{
                    return require(`../../assets/pfp/20${headIconPath}.png`);
                } catch (e) {
                    return require(`../../assets/pfp/no-texture.png`);
                }
            })()}/>
            <p>{scoreForUser} RS</p>
        </div>
  )
}

export default UnitRSDisplay