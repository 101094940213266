import React from 'react'
import InvViewing from './InvViewing'
import InvRelic from './InvRelic'
import { useState, useEffect } from 'react'
import InventoryInfo from './InventoryInfo'
import MobileInvInfo from './MobileInvInfo'

function ScoredInventory({currInventory, viewportWidth, mobileSize, hideBanner}) {
    const relicList = currInventory.charList.flatMap((curr) => curr.relicList)
    const [invViewing, setInvViewing] = useState(null)
    const [isViewing, setIsViewing] = useState(false);
    const handleChangeInvViewing = (relicData) => {
        setInvViewing(relicData)
        setIsViewing(old => old === false ? true : true)
    }

    const renderRelics = relicList
    .sort((a, b) => {
        return b.score - a.score
    })
    .map((curr, index) => {
        return(
            <InvRelic key={index} imagePath={`${curr.set[0]}-${curr.type}.png`} score={curr.score} relicData={curr} setInvViewing={data => handleChangeInvViewing(data)}/>
        )
    })

    useEffect(() => {
        if (relicList.length > 0) {
            setInvViewing(relicList[0])
        }
        //eslint-disable-next-line
    }, [currInventory])    
    return (
        <div className='scale-inventory-container' style={{width: '100%'}}>
            {!hideBanner && viewportWidth > mobileSize && <InventoryInfo currInventory={currInventory}/>}
            {!hideBanner && viewportWidth <= mobileSize && <MobileInvInfo currInventory={currInventory}/>}

            {/* normal shit */}
            <div style={{flex: 1, display: 'flex', flexWrap: 'wrap-reverse', alignItems:'flex-end', justifyContent: 'center', marginTop: hideBanner ? '0px' : '32px'}}>
                <div className='scored-inventory' style={viewportWidth <= mobileSize ? {gridTemplateColumns: 'repeat(auto-fit, minmax(60px, 1fr))'} : {}}>
                    {renderRelics}
                </div>
                {viewportWidth > mobileSize && <InvViewing invViewing={invViewing} />}
            </div>

            {/* mobile shit */}
            {viewportWidth <= mobileSize && isViewing &&
            <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', position: 'fixed', width: '100%', height: '100%', backgroundColor: '#00000099', top: 0, left: 0, zIndex: 9999, backdropFilter: 'blur(5px)'}} onClick={() => setIsViewing(false)}>
                <InvViewing invViewing={invViewing} />
                <button style={{background: 'none', border: 'none', color: 'white', margin:'16px', fontFamily: 'Anuphan', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setIsViewing(false)}>⤺ Back to inventory</button>
            </div>
            }
        </div>
    )
}

export default ScoredInventory