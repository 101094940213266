const findColor = (score) => {
    switch (true) {
        case score >= 50:
            return '#ffe14a'
        case score >= 40:
            return '#b02a2a'
        case score >= 35:
            return '#ff4f4f'
        case score >= 30:
            return '#ed5642'
        case score >= 20:
            return '#db6e30'
        case score >= 10:
            return '#cf8863'
        case score >= 5:
            return '#806b55'
        default:
            return '#615e5a'
    }
}

const findRank = (score) => {
    switch (true) {
        case score >= 50:
            return 'GOD';
        case score >= 40:
            return 'SSS';
        case score >= 35:
            return 'SS';
        case score >= 30:
            return 'S';
        case score >= 20:
            return 'A';
        case score >= 10:
            return 'B';
        case score >= 5:
            return 'C';
        default:
            return 'D';
    }
}

const findInvRank = (score) => {
    switch (true) {
        case score >= 2000:
            return 'GOD';
        case score >= 1750:
            return 'SSS';
        case score >= 1500:
            return 'SS';
        case score >= 1250:
            return 'S';
        case score >= 1000:
            return 'A';
        case score >= 750:
            return 'B';
        case score >= 500:
            return 'C';
        default:
            return 'D';
    }
}

const invValueColor = (score) => {
    switch (true) {
        case score >= 2000:
            return '#fffc4a'
        case score >= 1750:
            return '#b02a2a'
        case score >= 1500:
            return '#ff4f4f'
        case score >= 1250:
            return '#ed5642'
        case score >= 1000:
            return '#ed8142'
        case score >= 750:
            return '#cc9e70'
        case score >= 500:
            return '#806b55'
        default:
            return '#615e5a'
    }
}

const findCharRank = (score) => {
    switch (true) {
        case score >= 300:
            return 'GOD'
        case score >= 240:
            return 'SSS'
        case score >= 210:
            return 'SS'
        case score >= 180:
            return 'S'
        case score >= 150:
            return 'A'
        case score >= 120:
            return 'B'
        case score >= 60:
            return 'C'
        default:
            return 'D'
    }
}

const charValueColor = (score) => {
    switch (true) {
        case score >= 300:
            return '#fffc4a'
        case score >= 240:
            return '#b02a2a'
        case score >= 210:
            return '#ff4f4f'
        case score >= 180:
            return '#ed5642'
        case score >= 150:
            return '#ed8142'
        case score >= 120:
            return '#cc9e70'
        case score >= 60:
            return '#806b55'
        default:
            return '#615e5a'
    }
}

const invValueRank = (score) => {
    switch (true) {
        case score >= 2000:
            return 'Among Top Players'
        case score >= 1500:
            return 'Above Average'
        case score >= 1250:
            return 'Upper-half Average'
        case score >= 1000:
            return 'Lower-half Average'
        case score >= 750:
            return 'Below Average'
        case score >= 500:
            return 'Very Below Average'
        default:
            return 'Exceedingly Below Average'
    }
}

const profileRsColor = (score) => {
    if (score > 40){
        return '#ffe882'
    }else {
        return '#FFFFFF'
    }
}

const profileInvValueColor = (score) => {
    if (score > 1500){
        return '#ffe882'
    }else {
        return '#FFFFFF'
    }
}

const statValueColor = (statType, value) => {
    const highStatMap = {
        crit_dmg: 2.0,
        crit_rate: 0.8,
        atk: 4000,
        spd: 160,
        hp: 5000,
        def: 3000,
        effect_hit: 1,
        break_dmg: 2
    }
    const midStatMap = {
        crit_dmg: 1.5,
        crit_rate: 0.7,
        atk: 3000,
        spd: 134,
        hp: 4000,
        def: 2500,
        effect_hit: 0.5,
        break_dmg: 1.5
    }
    return (highStatMap[statType] || midStatMap[statType] 
        ? value >= highStatMap[statType]
            ? '#FFE675'
            : value >= midStatMap[statType]
                ? '#C2855D'
                : 'white'
        : 'white'
    )
}

export {findColor, findRank, findInvRank, charValueColor, findCharRank,  invValueColor, invValueRank, profileRsColor, profileInvValueColor, statValueColor}