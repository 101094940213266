import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce } from 'lodash';
import userDbService from '../services/userdb-service'
import UidSearchBar from '../comps/uid-input/UidSearchBar'
import SearchedUser from '../comps/uid-input/SearchedUser'

function Search({viewportWidth, mobileSize}) {

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [inputSearch, setInputSearch] = useState(searchParams.get(''))
    const [uidList, setUidList] = useState(null)
    const [showLoading, setShowLoading] = useState(true)
    const [sortOption, setSortOption] = useState('inventoryScore');

    const formHandler = {
        searchUser: async (e) => {
            e.preventDefault()
            if (inputSearch === '') {return} 
            setSearchParams({'': inputSearch})
        },
        viewUser: (uid) => {
            return navigate(`/uid/${uid}`)
        }
    }

    const renderUidList = uidList !== null 
    ? uidList
        .sort((a, b) => {
            switch (sortOption) {
                case 'alphabetical':
                    return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
                case 'lastUpdated':
                    const bDate = new Date(b.lastUpdated)
                    const aDate = new Date(a.lastUpdated)
                    return bDate - aDate
                case 'uidSort':
                    return a.uid.toString().substring(1) - b.uid.toString().substring(1)
                default:
                    return b.invScore - a.invScore 
            }
        })
        .map((curr, index) => {
            return(
            <SearchedUser user={curr} formHandler={formHandler} viewportWidth={viewportWidth} mobileSize={mobileSize} key={index}/>
            )
        })
    : []

    const initiateSearch = debounce(async () => {
        try {
            const searchResult = await userDbService.searchUser(searchParams.get(''))
            setUidList(searchResult)
            setInputSearch(searchParams.get(''))
        }
        catch(e) {
            alert(e.response.data.error)
        }
        setShowLoading(false)
    }, 300)

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Search Results'
        return () => {
            initiateSearch.cancel()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        setShowLoading(true)
        setUidList(null)
        initiateSearch()
        document.title = `${searchParams.get('')} - Search Results`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])


return (
    <div className="root" style={{backgroundImage: 'none'}}>
        <div className="app" style={{minHeight: '650px'}}>
            <h2 style={{fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px'}}>🔍 SEARCH FOR PROFILES</h2>
            <p>Discover and connect with fellow gamers by searching for account using their in-game UID or player's nickname.</p>
            <p style={{color: '#848484', fontSize: '12px', marginBottom: '36px'}}><b>Note:</b> Nickname search is only available for accounts that have already been indexed into our database via uid input. By searching UID you will index your public game-records into our database, including all your character's in your public showcase. Your profile can also be updated by anyone at any time. During Hoyoverse maintenance hours, we will not be able to process new UIDs, and profiles will not be able to update.</p>
            <UidSearchBar formHandler={formHandler} inputSearch={inputSearch} setInputSearch={setInputSearch}/>
            
            <div style={{display: 'flex', flexWrap: 'wrap-reverse', gap: '16px'}}>
                <div className='main-container' style={{padding: 0, flex: '100 1 auto', minWidth: viewportWidth >= mobileSize ? '550px' : '320px', marginBottom: 0}}>
                    <div className='form-panel' style={{padding: '16px'}}>
                        
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            
                            {(uidList === null || uidList.length === 0) && showLoading === false &&
                            <>
                                <h1 style={{fontWeight: 400, marginBottom: 0, fontSize: viewportWidth >= mobileSize ? '25px' : '20px', margin: 0}}>NO PROFILES FOUND</h1>
                                <p>We could not find anything in our database that matches your search.</p>
                                <img alt='' src={require('../assets/not-found.png')} style={{width: '128px'}}/>
                            </>
                            }
                            {!(uidList === null || uidList.length === 0) &&
                            <>
                                <h1 style={{fontWeight: 400, marginBottom: 0, fontSize: viewportWidth >= mobileSize ? '25px' : '20px', margin: 0}}>Search Result(s): {uidList.length}</h1>
                                <p>Not you? Try searching your in-game UID.</p>
                            </>
                            }
                            {showLoading === true && 
                                <div style={{alignSelf: 'center', marginTop: '16px'}}>
                                    <img src={require('../assets/loading.gif')} style={{width: '64px'}} alt="loading..."/><br />
                                    <span>Loading...</span>
                                </div>
                            }
                            <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center'}}>
                                {renderUidList}
                            </div>
                        </div>
                    </div>
                </div>
                {/* sort options */}
                <div style={{width: '260px', display: 'flex', flexDirection: 'column', flex: '1 1 auto'}}>
                    <div className='main-container' style={{display: 'flex', flexDirection: 'column', padding: '16px', marginBottom: 0}}>
                        <h3 style={{marginTop: 0, marginBottom: '8px'}}>Sort Options:</h3>
                        <form style={{marginRight: '16px', display: 'flex', flexWrap: 'wrap'}}>
                            <label style={{marginRight: '16px', display: 'flex', alignItems: 'center'}}>
                                <input
                                type="radio"
                                value="inventoryScore"
                                checked={sortOption === 'inventoryScore'}
                                onChange={e => setSortOption(e.target.value)}
                                />
                                Inventory Score
                            </label>
                            <label style={{marginRight: '16px', display: 'flex', alignItems: 'center'}}>
                                <input
                                type="radio"
                                value="lastUpdated"
                                checked={sortOption === 'lastUpdated'}
                                onChange={e => setSortOption(e.target.value)}
                                />
                                Last Updated
                            </label>
                            <label style={{marginRight: '16px', display: 'flex', alignItems: 'center'}}>
                                <input
                                type="radio"
                                value="alphabetical"
                                checked={sortOption === 'alphabetical'}
                                onChange={e => setSortOption(e.target.value)}
                                />
                                Alphabetical (A-Z)
                            </label>
                            <label style={{marginRight: '16px', display: 'flex', alignItems: 'center'}}>
                                <input
                                type="radio"
                                value="uidSort"
                                checked={sortOption === 'uidSort'}
                                onChange={e => setSortOption(e.target.value)}
                                />
                                UID Age
                            </label>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
)
}

export default Search