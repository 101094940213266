import React from 'react'
import RVDisplay from '../RVDisplay'
import { findColor, findRank } from '../../utils/scoreStyle'

function CharRelic({curr, setRelicCalcs}) {
    //CURR IS CURR RELIC BTW since we .map this component
    //was too lazy to change shit



    return (
    <div
        className="charrelic"
        onClick={() => {
            console.log(curr)
            setRelicCalcs(curr)
        }}
        style={{outline: curr.score >= 50 ? '1px solid #FFE675' : '1px solid #2b2b2b', boxSizing: 'border-box', display: 'flex', borderRadius: '8px', flexDirection: 'column', position: 'relative', alignItems:'center', width: '160px', backgroundColor: '#00000034', overflow: 'hidden'}}>
        
        {curr.penalties.filter(curr => curr !== null).filter(curr => curr[2] !== 0).length !== 0 && 
        <img alt='' src={require('../../assets/icons/info-icon.png')} style={{position: 'absolute', top: '8px', right: '8px', width: '14px'}}/>}
        
        {/* relic main info display */}
        <div style={{display: 'flex', width: '160px'}}>
        <img alt='' style={{height: '44px', width: '44px', margin: '8px', filter: 'drop-shadow(2px 2px 6px #000000)'}}src={(() => {
            try {
            return require(`../../assets/relics/${curr.set[0]}-${curr.type}.png`);
            }catch (e) {
            return require(`../../assets/relics/no-texture.png`);
            }
        })()}/>
        <div>
            <p style={{fontSize: '11px', margin: '16px 0px 0px 0px', textTransform: 'capitalize'}}>{curr.type}</p>
            <p className='fade-text'  style={{width: '92px', margin: '0', fontWeight: 600, fontSize: '14px'}}>{curr.mainstatDisplay}</p> 
        </div>
        </div>

        {/* relic little details display */}
        <div style={{display: 'flex', justifyContent: 'space-between', width: '160px'}}>
        <div style={{margin: '0px 8px 0px 8px'}}>
            <p style={{margin: 0, fontSize: '11px'}}>Score: </p>
            <b style={{margin: 0, fontSize: '16px', color: findColor(curr.score)}}>{curr.score.toFixed(1)} </b>
        </div>
        <div style={{margin: '0px 8px 0px 8px'}}>
            <p style={{margin: 0, fontSize: '11px'}}>Rank: </p>
            <b style={{margin: 0, fontSize: '16px', color: findColor(curr.score)}}>{findRank(curr.score)} </b>
        </div>
        <div style={{margin: '0px 8px 0px 8px'}}>
            <p style={{margin: 0, fontSize: '11px'}}>Eff. Stat: </p>
            <b style={{margin: 0, fontSize: '16px', color: curr.effStats < 3 ? '#848484' : curr.effStats === 3 ? '#C2855D' : '#FFE675' }}>{curr.effStats}<span style={{fontWeight: 300, fontSize: '11px'}}>/4</span></b>
        </div>
        </div>

        {/* relic subs display */}
        <div style={{margin: '8px', height: '100%', width: '160px'}}>
            {Object.keys(curr.subs)
            .map((key => {
                return curr.subs[key]
            }))
            .map((currSub, index) => {
                const objForRVDisplay = {
                    display: currSub[0],
                    value: currSub[1],
                    rollCombo: currSub[2],
                    weight: currSub[3]
                }
                return <RVDisplay key={`rvdisplay${index}`} sub={objForRVDisplay} index={index} useShortDisplay={true} txtSize={'11px'} rollSize={'9px'} valWidth={'38px'} valMargin={'0px'} displayWidth={'40px'} fullHeight={'20px'}/>
            })}
        </div>
    </div>
  )
}

export default CharRelic