import React from 'react'
import { findColor } from '../../utils/scoreStyle'

function InvRelic({imagePath, score, relicData, setInvViewing}) {
  return (
    <button onClick={() => setInvViewing(relicData)} className='inv-relic' style={{border: `1px solid ${findColor(score)}99` }}>
        <img src={(() => {
          try {
            return require(`../../assets/relics/${imagePath}`);
          }catch (e) {
            return require(`../../assets/relics/no-texture.png`);
          }
        })()} alt=''/>
        <p>{score} <span style={{color: 'grey'}}>RS</span></p>
    </button>
  )
}

export default InvRelic