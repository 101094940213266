import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Link } from 'react-router-dom';

function Home({viewportWidth, mobileSize}) {

    const [initialAnim, setInitialAnim] = useState(false)
    const [initialHero, setInitialHero] = useState(false)
    const [inputSearch, setInputSearch] = useState('')
    const [heroImg, setHeroImg] = useState(0)

    const styleH1 = {
        position: 'absolute',
        fontSize: '40px',
        maxWidth: '500px',
        margin: '0px',
        top: '20%',
        left: '0',
        zIndex: '2'
    }

    const mobileStyleH1 = {
        position: 'absolute',
        zIndex: '2',
        fontSize: '25px',
        wordWrap: 'break-word',
        margin: '32px'
    }

    const styleHero = {
        width: '350px',
        height: '350px',
        position: 'absolute',
        top: '15%',
        right: '0%',
        perspective: '1000px',
        perspectiveOrigin: '50% 50%'
    }

    const navigate = useNavigate()
    const handleSearch = (e) => {
        e.preventDefault()
        return navigate(`/search?=${inputSearch}`)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        setInitialAnim(true)
        setHeroImg(Math.floor(Math.random() * 6))
        setTimeout(() => {
            setInitialHero(true)
        }, 150);
        document.title = 'Honkai: Star Rail Stats, Relic Scorer & More'
    }, [])

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#0F0F0F'}}>
                

                <div className='home-hero' style={{height: viewportWidth >= mobileSize ? '500px' : '350px', borderBottomLeftRadius: viewportWidth >= mobileSize ? '200px' : '0px', display: 'flex', justifyContent: 'center' }}> 
                    <div style={{width: viewportWidth >= mobileSize ? '75%': '100%', maxWidth: '1080px', position: 'relative'}}>

                
                        <div className={`animated-element ${initialAnim ? 'animate' : ''}`} style={viewportWidth >= mobileSize ? styleH1 : mobileStyleH1}>
                            <h3 style={{margin: 0}}>STAR RAIL STATS TRACKER</h3>
                            <p style={{margin: '16px 0px 32px 0px', fontSize: '18px', maxWidth: '350px'}}>Fast, free, and automatic account reviews for Honkai: Star Rail</p>

                            <form onSubmit={(e) => handleSearch(e)} style={{width: '250px', display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #FFFFFF55', overflow: 'hidden', minHeight: '46px', margin: '0', backgroundColor: '#00000066',backdropFilter: 'blur(2px)'}}>
                                <input
                                    className="round-input"
                                    placeholder="UID or username"
                                    value={inputSearch}
                                    onChange={e => setInputSearch(e.target.value)}
                                    style={{height: '100%', flex: 1, border: 'none', borderRadius: 0, margin: 0, paddingLeft: '12px',background: 'none', maxWidth: '200px'}}
                                ></input>
                                <button style={{display: 'flex', justifyContent: 'center', alignItems: 'center', aspectRatio: '1/1', height: '100%', color: 'white', background: 'none', border: 'none', cursor: 'pointer', fontFamily: 'Anuphan', marginRight: '8px'}}>
                                    <img style={{height: '18px', opacity: '75%'}} alt='Start search' src={require('../assets/icons/search-icon.png')}/>
                                </button>
                            </form>
                            <p style={{fontSize: '14px', color: '#A5A5A5', maxWidth: '300px'}}>Searching will make your profile open to the public on this website.</p>
                        </div>
                        {viewportWidth >= mobileSize &&
                            <div className={`animated-element ${initialHero ? 'animate' : ''}`} style={styleHero}>
                                <img alt='' className={`home-hero-demo ${initialHero ? 'initial' : ''}`} style={{width: '727px'}} src={require(`../assets/demo-showcase${heroImg}.png`)}/>
                            </div>
                        }


                    </div>
                </div>


                <div style={{width: viewportWidth >= mobileSize ? '75%': '100%', maxWidth: '1080px', alignSelf: 'center', margin: viewportWidth >= mobileSize ? '32px 0px 32px 0px': '0px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                    <div className='home-feature'>
                        <div style={{margin: '32px'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img alt='' style={{width: '32px', height: '32px', marginRight: '16px', zIndex: 1}} src={require('../assets/icons/flex-icon.png')}/>
                                <h2 style={{fontWeight: '400', margin: 0, zIndex: 1}}>Showcase</h2>
                            </div>
                            <p style={{color: '#AAA', zIndex: 1}}>We have individual profile pages for all of our registered accounts. Showcase your character builds in elegant card designs directly on your profile for all to see.</p>
                            <Link className='home-feature--link' to='/uid/600527748'>View example</Link>
                        </div>
                        <img alt='' className='home-feature--bg' style={{width: '100%'}} src={require('../assets/feature2.png')}/>
                    </div>
                    <div className='home-feature'>
                        <div style={{margin: '32px'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img alt='' style={{width: '32px', height: '32px', marginRight: '16px', zIndex: 1}} src={require('../assets/icons/score-icon.png')}/>
                                <h2 style={{fontWeight: '400', margin: 0, zIndex: 1}}>Relic Scorer</h2>
                            </div>
                            <p style={{color: '#AAA', zIndex: 1}}>Easily access relic stats via UID lookup or manual stat input for tailored evaluations. Our scoring algorithm is also open to the public for suggestions & improvements.</p>
                            <Link className='home-feature--link' to='/relic-scorer'>See more</Link>
                        </div>
                        
                        <img alt='' className='home-feature--bg' style={{width: '100%'}} src={require('../assets/feature1.png')}/>
                    </div>
                    <div className='home-feature'>
                        <div style={{margin: '32px', height: '100%'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img alt='' style={{width: '32px', height: '32px', marginRight: '16px', zIndex: 1}} src={require('../assets/icons/leaderboards-icon.png')}/>
                                <h2 style={{fontWeight: '400', margin: 0, zIndex: 1}}>Leaderboards</h2>
                            </div>
                            <p style={{color: '#AAA', zIndex: 1}}>We rank players based soley off relic inventory quality. Whales don't dominate here; only those who are truly worthy do. See how you stack up against others.</p>
                            <Link className='home-feature--link' to='/leaderboards'>Leaderboards</Link>
                        </div>
                        <img alt='' className='home-feature--bg' style={{width: '100%'}} src={require('../assets/feature3.png')}/>
                    </div>
                </div>
            </div>
        
        <div style={{width: viewportWidth >= mobileSize ? '65vw': '100%', maxWidth: '1080px', alignSelf: 'center', padding: '16px',boxSizing: 'border-box', margin: viewportWidth >= mobileSize ? '32px 0px 32px 0px': '0px', backgroundImage: 'hero-desktop.png'}}>
            <h2 style={{fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px'}}>💡 {viewportWidth >= mobileSize ? 'FREQUENTLY ASKED QUESTIONS' : 'FAQ'}</h2>
            <p>Q: What stats are tracked?</p>
            <p style={{color: '#A5A5A5'}}>A: All the characters (up to 8) in your in-game support and starfaring companions, as well as all the gear they hold. Your general information is also tracked. 8 is a good number of characters to base your account off, because the end-game typically requires two decently built teams of 4 characters.</p>
            
            <p>Q: How do I see my profile?</p>
            <p style={{color: '#A5A5A5'}}>A: Simply search your UID in any of the search bars on this website that allow UID or username input. Alternatively, <Link to="/search">click here</Link> to go directly to the search page. User's that have already been searched with a UID in the past can be found using their display name.</p>
        
            <p>Q: Is this website safe to use?</p>
            <p style={{color: '#A5A5A5'}}>A: Yes. We only fetch your already public data (the stuff in your in-game support and starfaring companions). Sharing your UID does not risk your privacy or security in any way.</p>

            <p>Q: Why can't I find my profile by searching my username?</p>
            <p style={{color: '#A5A5A5'}}>A: Your username is most likely too common, or your search parameters may be too vague. If you have trouble searching for your profile, we reccommend you to bookmark your profile for easier access.</p>
                    
            <p>Q: I can't find my UID during in-game maintenance.</p>
            <p style={{color: '#A5A5A5'}}>A: New accounts cannot be proccessed during Hoyoverse maintenance hours. However, you can still search accounts that have previously been tracked before. Additionally, these accounts will NOT be able to refresh during Hoyoverse maintenance hours.</p>

            <p>Q: Can anyone refresh my profile?</p>
            <p style={{color: '#A5A5A5'}}>A: Yes. Your profile is 100% open to the public and can be refreshed by anyone at any time.</p>
            
            <p>Q: I believe there are issues with the scoring meta.</p>
            <p style={{color: '#A5A5A5'}}>A: Honkai Star Rail's meta is constantly evolving. I try my hardest to keep the meta as relevant as possible. Most of my information is from <a href='https://www.prydwen.gg/star-rail/'>https://prydwen.gg/</a>, as well as many other theory crafters and content creators on YouTube. If I missed anything, or you would like to propose a possible change, you can find my contact information <Link to='/contact'>here</Link>. I will soon add a community voting feature where meta change requests can be proposed and upvoted by the playerbase. </p>
        </div>
        
        </div>
    )
}

export default Home