import React, { useState, useEffect } from 'react';
import CharRelic from './CharRelic';
import { charValueColor, statValueColor } from '../../utils/scoreStyle'
import { debounce } from 'lodash'
import RelicCalcs from './RelicCalcs';

function BuildCard({charIn, user, chars, bgType, isModded}) {

  const [triggerAnimation, setTriggerAnimation] = useState(false)
  const [graphAnim, setGraphAnim] = useState(false)
  const [barAnim, setBarAnim] = useState(false)
  const [currTab, setCurrTab] = useState(0)
  const [charCompare, setCharCompare] = useState(null)
  const [currBuild, setCurrBuild] = useState(0)
  const [relicCalcs, setRelicCalcs] = useState(null)

  const renderStats = (incomingStats, incomingChar) => {
    let output = []
    let index = 0
    for(const stat in incomingStats){
      output.push(
        <p key={incomingChar.id + stat} className='char-stats-display' style={{backgroundColor: index % 2 !== 0 ? '' : '#00000034'}}>
          <span className='fade-text' style={{width: '165px', marginRight: '8px', color: '#848484'}}>{incomingStats[stat].display}:</span> 
          <span style={{color: statValueColor(stat, incomingStats[stat].value)}}>
          {['HP', 'ATK', 'DEF', 'SPD'].includes(incomingStats[stat].display) 
            ? `${Math.floor(incomingStats[stat].value)}`
            : `${Math.floor(incomingStats[stat].value * 10000) / 100}%`}
          </span>
        </p>
      )
      index ++
    }
    return output
  }
    
  const renderSets = (incomingSets, incomingChar) => {
    let count = 0
    let sets = []

    for (const i in incomingSets){
      if (incomingSets[i].count >= 2){
        sets.push(
          <p key={incomingChar.id + i} className='fade-text' style={{width: '100%', margin: 0, color: '#848484'}}>[{incomingSets[i].strong ? '✅' : '⚠️'}] {incomingSets[i].count === 3 ? '2' : incomingSets[i].count}x <span style={{color: incomingSets[i].strong ? 'white' : '#848484'}}>{incomingSets[i].display}</span></p>
        )
        count = incomingSets[i].strong ? count + incomingSets[i].count : count
      }
    }
    
    return (
      <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#00000034', padding: '8px 8px 8px 8px', marginTop: '8px'}}>
        <p style={{margin: 0, fontSize: '14px'}}>Active Set Combos: </p>
        <p style={{margin: 0, fontSize: '12px'}}><b style={{margin: 0, fontSize: '22px', color: count === 6 ? 'white' : '#848484'}}>{count === 3 ? '2' : count}</b>/6 (Currently meta-approved)</p>
        {sets}
      </div>
    )
  }

  const renderRelics = charIn.relicList.map((curr, index) => {
    return(
      <CharRelic key={`charRelic${index}`} curr={curr} setRelicCalcs={setRelicCalcs} />
    )
  })

  const [percentages, setPercentages] = useState({
    crit: 0,
    spd: 0,
    atk: 0,
    debuff: 0,
    sustain: 0
  })
  const [rollCount, setRollCount] = useState({
    crit: 0,
    spd: 0,
    atk: 0,
    debuff: 0,
    sustain: 0
  })

  const handleSwitchTab = (tabIndex) => {
    setCurrTab(tabIndex)
    setGraphAnim(false)
    setBarAnim(false)
    setTimeout(() => {
        setGraphAnim(true)
        setBarAnim(true)
    }, 50)
  }

  const handleSwitchBuild = (buildIndex) => {
    setCurrBuild(buildIndex)
    setBarAnim(false)
    setTimeout(() => {
        setBarAnim(true)
    }, 50)
  }

  const findSpdBreakpoint = (givenSpd) => {
    if (givenSpd < 134){
      return 0 + (((givenSpd - 90) / (134-90)) * 25)
    }
    else if (givenSpd < 143){
      return 25 + (((givenSpd - 134) / (143-134)) * 25)
    }
    else if (givenSpd < 160){
      return 50 + (((givenSpd - 143) / (160-143)) * 25)
    }
    else{
      return Math.min(75 + (((givenSpd - 160) / (200-160)) * 25), 100)
    }
  }

  const findStatEval = (statCompare, givenStat) => {
    if (typeof statCompare === 'number'){
      if (givenStat <= statCompare) {
        return 0 + ((givenStat/statCompare) * 50) 
      } 
      else {
        if (statCompare === 0 ) return 75
        return 50 + ((givenStat/(statCompare * 4)) * 50) 
      }
    }

    if (givenStat < statCompare[0]){
      return 0 + ((givenStat/statCompare[0]) * 32)
    }
    else if (givenStat < statCompare[1]){
      return 32 + (((givenStat - statCompare[0]) / (statCompare[1]-statCompare[0])) * 18)
    }
    else{
      return Math.min(50 + (((givenStat - statCompare[1]) / ((statCompare[1] * 1.25) - statCompare[1])) * 49), 99)
    }
  }

  const renderBars = (buildObj) => {

    const translator = {
      spd: 'Speed',
      hp: 'HP',
      def: 'DEF',
      atk: 'ATK',
      break_dmg: 'Break Effect',
      effect_hit: 'Effect Hit Rate',
      effect_res: 'Effect RES',
      crit_dmg: 'CRIT DMG',
      crit_rate: 'CRIT Rate',
      sp_rate: 'Energy Regen',
      heal_rate: 'Outgoing Healing',
      physical_dmg: 'Physical DMG',
      fire_dmg: 'Fire DMG',
      ice_dmg: 'Ice DMG',
      wind_dmg: 'Wind DMG',
      thunder_dmg: 'Lightning DMG',
      quantum_dmg: 'Quantum DMG',
      imaginary_dmg: 'Imaginary DMG',
    }
    //render stat goals
    return Object.keys(buildObj).map((curr, index) => {
      if (curr !== 'display'){
        const fillWidth = charIn.stats[curr] ? findStatEval(buildObj[curr],  charIn.stats[curr].value) : 0
        return (
          <div key={`chareval${buildObj.display}${index}`}>
            <p style={{margin: '12px 0px 12px 0px'}}>{translator[curr]}</p>
            <div
              className='progress-bar'
              style={{
                background: 'linear-gradient(90deg, rgba(125,39,39,1) 0%, rgba(143,135,38,1) 45%, rgba(54,103,45,1) 55%, rgba(54,103,45,1) 100%)',
              width: '100%', height: '8px', position: 'relative', justifyContent: 'center', margin: '4px 0px 16px 0px'}}>
              
              <div className='progress-bar' style={{zIndex: 1, background: 'none', position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>
                <b>|</b>
                <span style={{position: 'absolute', top: '20px'}}>{
                  typeof buildObj[curr] === 'number'
                    ? buildObj[curr] < 5 ? `${(buildObj[curr] * 100).toFixed(1)}%` : buildObj[curr]
                    : buildObj[curr][1] < 5 ? `${(buildObj[curr][1] * 100).toFixed(1)}%` : buildObj[curr][1]
                }</span>
              </div>
              {typeof buildObj[curr] !== 'number' && 
              <div className='progress-bar' style={{zIndex: 1, background: 'none', position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '30%'}}>
                <b>|</b>
                <span style={{position: 'absolute', top: '20px'}}>{buildObj[curr][0] < 5 ? `${(buildObj[curr][0] * 100).toFixed(1)}%` : buildObj[curr][0]}</span>
              </div>
              }

              <div className='progress-bar-fill' style={{left: 0, position: 'absolute', width: `${barAnim ? fillWidth : 0}%`, height: '50%', margin: '0px 2px 0px 2px', backgroundColor: '#FFE675', borderRadius: '99px'}}>
                <span style={{position: 'absolute', right: '-6px', top: '-18px', pointerEvents: 'none'}}>▼</span>
              </div>
            </div>
          </div>
        )
      }
      return null
    })
  }

  useEffect(() => {
    const findDistribution = debounce(() => {

      let statsArr = [0, 0, 0, 0, 0]
  
      charIn.relicList.forEach(currRelic => {
        Object.keys(currRelic.subs).forEach(currSub => {
          const rolls = currRelic.subs[currSub][2].length
          const display = currRelic.subs[currSub][0]
          if (display.includes('CRIT')) statsArr[0] += rolls
          else if (display.includes('SPD')) statsArr[1] += rolls
          else if (display.includes('ATK')) statsArr[2] += rolls
          else if (display.includes('Break') || display.includes('Hit')) statsArr[3] += rolls
          else if (display.includes('HP') || display.includes('DEF') || display.includes('Res')) statsArr[4] += rolls
        })
      })
      const maxStat = Math.max(...statsArr)
      setPercentages({
        crit: statsArr[0]/maxStat || 0,
        spd: statsArr[1]/maxStat || 0,
        atk: statsArr[2]/maxStat || 0,
        debuff: statsArr[3]/maxStat || 0,
        sustain: statsArr[4]/maxStat || 0
      })
      setRollCount({
        crit: statsArr[0],
        spd: statsArr[1],
        atk: statsArr[2],
        debuff: statsArr[3],
        sustain: statsArr[4]
      })
    }, 300)
    findDistribution()
    setCharCompare(chars.find(curr => {
      return parseInt(charIn.id) === curr.id
    }))
    setTriggerAnimation(false)
    const timeout = setTimeout(() => {
        setTriggerAnimation(true)
    }, 300)
    return () => clearTimeout(timeout)
  }, [charIn, chars])

  useEffect(() => {
    setTriggerAnimation(false)
    const timeout = setTimeout(() => {
        setTriggerAnimation(true)
    }, 300)
    return () => clearTimeout(timeout)
  }, [bgType])

  useEffect(() => {
    setCurrBuild(0)
    setBarAnim(false)
    const timeout = setTimeout(() => {
      setBarAnim(true)
    }, 10)
    return () => clearTimeout(timeout)
  },[charIn])

  return (
    <div className="profile-stat-box" style={{display: 'flex', flexDirection: 'column', padding: 0, margin: '8px 0px 16px 0px', position: 'relative', overflow: 'hidden', backgroundColor: '#0F0F0F'}}>
      <img alt='' className={`char-splash-leftmask ${triggerAnimation ? bgType === 0 ? 'default' : bgType === 1 ? 'char': 'none' : ''} `} src={(() => {
        try {
          if (bgType === 0) return require(`../../assets/clearoff.jpg`);
          return require(`../../assets/mini-splash/${charIn.id}.png`);
        } catch (e) {
          return require(`../../assets/mini-splash/no-texture.png`);
        }
      })()} style={{height: '105%', position: 'absolute', top: '-5px', right: '-200px', pointerEvents: 'none'}}/>

      <small style={{pointerEvents: 'none', opacity: '50%',position: 'absolute', bottom: '8px', right: '8px', margin: 0}}>UID: {user.uid}</small>
      
      <div className="profile-stat-box" style={{background: 'none', display: 'flex', flexDirection: 'column', padding: '2px', margin: '0px', position: 'relative', overflowX: 'auto',}}>
        
        <div style={{display: 'flex', alignItems: 'center', margin: '16px 0px 0px 0px', minWidth: '900px'}}>
          
          <div style={{width: '402px', margin: '0px 16px 0px 16px'}}>
            <h3 style={{fontWeight: 500, margin: 0}}>{charIn.displayName}</h3>
            <h3 style={{fontWeight: 500, margin: 0}}>Lvl {charIn.level}, <span style={{color: charIn.rank >= 2 ? charIn.rank === 6 ? '#FFE675': '#C2855D' : 'white'}}>E{charIn.rank}</span></h3>
          </div>
          
          <button
            className={`build-card-tab ${currTab === 0 ? 'selected': ''}`}
            style={{borderTopLeftRadius: '16px'}}
            onClick = {currTab === 0 ? () => {} : () => handleSwitchTab(0)}
          >
            Equipped Items
          </button>
          
          <button 
            className={`build-card-tab ${currTab === 1 ? 'selected': ''}`}
            onClick = {currTab === 1 ? () => {} : () => handleSwitchTab(1)}
          >
            Stat Distribution
          </button>

          <button
            className={`build-card-tab ${currTab === 2 ? 'selected': ''}`}
            style={{borderTopRightRadius: '16px'}}
            onClick = {currTab === 2 ? () => {} : () => handleSwitchTab(2)}
          >
            Endgame Power
          </button>
        </div>

        <div style={{display: 'flex', padding: 0, margin: '0px 0px 16px 0px',}}>

          <div style={{maxWidth: '144px', minWidth: '144px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: '16px 16px 16px 16px', paddingRight: '16px', borderRight: '1px solid #848484'}}>
            
            <img alt='' style={{width: '100%', marginBottom: '4px', borderRadius: '8px', border: '1px solid white', boxSizing: 'border-box'}} src={(() => {
              try {
                return require(`../../assets/mini-splash/${charIn.id}.png`);
              } catch (e) {
                return require(`../../assets/mini-splash/no-texture.png`);
              }
            })()}/>

            <div style={{display: 'flex', justifyContent: 'space-between', padding: '8px 10px 0px 10px'}}>
              <div style={{textAlign: 'center'}}>
                <p style={{margin: 0, fontSize: '14px'}}>Total RS: </p>
                <b style={{margin: 0, fontSize: '20px', color: charValueColor(charIn.totalRs ? charIn.totalRs : 0)}}>{charIn.totalRs ? charIn.totalRs.toFixed(1) : '0.0'} </b>
              </div>
              <div style={{textAlign: 'center'}}>
                <p style={{margin: 0, fontSize: '14px'}}>Avg. RS: </p>
                <b style={{margin: 0, fontSize: '20px', color: charValueColor(charIn.totalRs ? charIn.totalRs : 0)}}>{charIn.avgRs.toFixed(1)} </b>
              </div>
            </div>
            
            {charIn.bracket &&
            charIn.bracket !== -1 ?
            <p style={{margin: 0, fontSize: '12px', textAlign: 'center'}}>(
              {charIn.bracket < 0.5 ? 'Top ' : 'Bottom '} {`${(Math.max((charIn.bracket * 100), 1)).toFixed(0)}%`} bracket{isModded ? '?' : ''})
            </p>
            : <p style={{margin: 0, fontSize: '12px', textAlign: 'center'}}>(No global rankings)</p>}
            
            <div style={{marginTop: '8px'}}>
                <img alt='' style={{width: '140px', margin: '8px 0px 8px 0px'}} src={(() => {
                  try {
                    return require(`../../assets/lightcones/${charIn.lightconeId}.png`);
                  } catch (e) {
                    return require(`../../assets/lightcones/no-texture.png`);
                  }
                })()}/>
            </div>
            
            <small style={{margin: 0}}>({charIn.lightconeDisplay})</small>
            <p style={{margin: 0, color: '#848484'}}>Rank: <span style={{color: 'white'}}>S{charIn.lightconeRank}</span></p>
            {charIn.hasSignature !== undefined && <p style={{margin: 0, color: '#848484'}}>Signature? {charIn.hasSignature ? "✅" : "❌"}</p>}
          </div>

          <div style={{maxWidth: '224px', minWidth: '224px', margin: '16px 0px 16px 0px'}}>
              {renderStats(charIn.stats, charIn)}
              {renderSets(charIn.sets, charIn)}
          </div>

          <div style={{display: 'flex', minWidth: '528px', maxWidth: '528px', height: '474px', position: 'relative', margin: '0px 16px 16px 16px', boxSizing: 'border-box', borderRadius: '0px 16px 16px 16px', backgroundColor: '#00000034'}}>
            
            {relicCalcs !== null && chars &&
              <RelicCalcs relic={relicCalcs} setRelicCalcs={setRelicCalcs} chars={chars}/>
            }

            {currTab === 0 && 
              <div style={{padding: '16px', position: 'relative', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', gap: '8px'}}>
                {renderRelics}
              </div>
            }

            {currTab === 1 && 
            <div
              className='grid-bg'
              style={{
                flex: 1, flexDirection: 'column', margin: '16px', display: 'flex', alignItems:'center', justifyContent: 'center',
                outline: '1px solid #2b2b2b', borderRadius: '8px', boxShadow: 'inset 0px 0px 6px #00000069',
              }}>          
              {/* radar graph*/}
              <div className='radar-graph'
                style={{
                  width: '400px', height: '400px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'
              }}>
                
                <div className='radar-container'>
                  <img alt='' style={{pointerEvents: 'none', position: 'absolute', width: '100%', height: '100%', top: 0}} src={require('../../assets/graph-bg.png')}/>
                  <div
                    className='radar-fill'
                    style={{
                      zIndex: -1,
                      transition: graphAnim ? '' : 'clip-path 0.0s ease',
                      clipPath: graphAnim ? 
                      `polygon(
                        ${(0.5 + Math.sin((2 * Math.PI)*0.0) * (0.5 * Math.max(percentages.crit, 0.05))) * 100}% ${(0.5 - Math.cos((2 * Math.PI)*0.0) * (0.5 * Math.max(percentages.crit, 0.05))) * 100}%,
                        ${(0.5 + Math.sin((2 * Math.PI)*0.2) * (0.5 * Math.max(percentages.atk, 0.05))) * 100}% ${(0.5 - Math.cos((2 * Math.PI)*0.2) * (0.5 * Math.max(percentages.atk, 0.05))) * 100}%,
                        ${(0.5 + Math.sin((2 * Math.PI)*0.4) * (0.5 * Math.max(percentages.sustain, 0.05))) * 100}% ${(0.5 - Math.cos((2 * Math.PI)*0.4) * (0.5 * Math.max(percentages.sustain, 0.05))) * 100}%,
                        ${(0.5 + Math.sin((2 * Math.PI)*0.6) * (0.5 * Math.max(percentages.debuff, 0.05))) * 100}% ${(0.5 - Math.cos((2 * Math.PI)*0.6) * (0.5 * Math.max(percentages.debuff, 0.05))) * 100}%,
                        ${(0.5 + Math.sin((2 * Math.PI)*0.8) * (0.5 * Math.max(percentages.spd, 0.05))) * 100}% ${(0.5 - Math.cos((2 * Math.PI)*0.8) * (0.5 * Math.max(percentages.spd, 0.05))) * 100}%
                      )`
                      : '',
                    }}
                  />

                </div>
                <div className='radar-label' style={{position: 'absolute' ,top: '-1%'}}>
                  <h1 style={{color: percentages.crit === 1 ? '#FFE675' : '' }}>CRIT Rate/DMG</h1>
                  <p>({rollCount.crit} rolls)</p>
                </div>
                
                <div className='radar-label' style={{position: 'absolute' ,top: '29%', left: '-4%'}}>
                  <h1 style={{color: percentages.spd === 1 ? '#FFE675' : '' }}>SPD</h1>
                  <p>({rollCount.spd} rolls)</p>
                </div>

                <div className='radar-label' style={{position: 'absolute' ,top: '29%', right: '-4%'}}>
                  <h1 style={{color: percentages.atk === 1 ? '#FFE675' : '' }}>ATK</h1>
                  <p>({rollCount.atk} rolls)</p>
                </div>

                <div className='radar-label' style={{position: 'absolute' ,bottom: '4.5%', right: '10%'}}>
                  <h1 style={{color: percentages.sustain === 1 ? '#FFE675' : '' }}>HP/DEF/RES</h1>
                  <p>({rollCount.sustain} rolls)</p>
                </div>

                <div className='radar-label' style={{position: 'absolute' ,bottom: '4.5%', left: '10%'}}>
                  <h1 style={{color: percentages.debuff === 1 ? '#FFE675' : '' }}>Break/EHR</h1>
                  <p>({rollCount.debuff} rolls)</p>
                </div>
              </div>
            </div>
            }

            {currTab === 2 && 
              <div
                style={{
                  flex: 1, flexDirection: 'column', margin: '16px', display: 'flex', padding: '16px', overflowY: 'auto',
                  outline: '1px solid #2b2b2b', borderRadius: '8px', boxShadow: 'inset 0px 0px 6px #00000069',
                }}
              >    
                {charCompare &&
                  <>
                    <div style={{marginBottom: '32px'}}>
                      <b>Speed Breakpoints</b>
                      <div 
                        className='progress-bar'
                        style={{width: '100%', height: '8px', position: 'relative', justifyContent: 'space-between', margin: '24px 0px 8px 0px'}}
                      >
                        <div className='progress-bar' style={{zIndex: 1, backgroundColor: '#FFE675'}}>
                          <p>✔</p>
                          <span style={{position: 'absolute', top: '20px'}}>{charCompare.speedBreakPoints.includes(90) ? '⭐' : ''}90</span>
                        </div>

                        <div className='progress-bar' style={{zIndex: 1, backgroundColor: charIn.stats.spd.value >= 134 && graphAnim ? '#FFE675' : ''}}>
                          <p>{charIn.stats.spd.value >= 134 && graphAnim ? '✔' : '🔒'}</p>
                          <span style={{position: 'absolute', top: '20px'}}>{charCompare.speedBreakPoints.includes(134) ? '⭐' : ''}134</span>
                        </div>

                        <div className='progress-bar' style={{zIndex: 1, backgroundColor: charIn.stats.spd.value >= 143 && graphAnim ? '#FFE675' : ''}}>
                          <p>{charIn.stats.spd.value >= 143 && graphAnim ? '✔' : '🔒'}</p>
                          <span style={{position: 'absolute', top: '20px'}}>{charCompare.speedBreakPoints.includes(143) ? '⭐' : ''}143</span>
                        </div>

                        <div className='progress-bar' style={{zIndex: 1, backgroundColor: charIn.stats.spd.value >= 160 && graphAnim ? '#FFE675' : ''}}>
                          <p>{charIn.stats.spd.value >= 160 && graphAnim ? '✔' : '🔒'}</p>
                          <span style={{position: 'absolute', top: '20px'}}>{charCompare.speedBreakPoints.includes(160) ? '⭐' : ''}160</span>
                        </div>

                        <div className='progress-bar' style={{zIndex: 1, backgroundColor: charIn.stats.spd.value >= 200 && graphAnim ? '#FFE675' : ''}}>
                          <p>{charIn.stats.spd.value >= 200 && graphAnim ? '✔' : '🔒'}</p>
                          <span style={{position: 'absolute', top: '20px'}}>{charCompare.speedBreakPoints.includes(200) ? '⭐' : ''}200</span>
                        </div>

                        <div className='progress-bar-fill' style={{position: 'absolute', width: `${graphAnim ? findSpdBreakpoint(charIn.stats.spd.value) : 0}%`, height: '100%', backgroundColor: '#FFE675', borderRadius: '99px'}}/>
                      </div>
                    </div>

                    {charCompare.endgameStats.length > 0 ? 
                    <div>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <b>Stat Goals</b>
                        <select className='substat-dropdown' style={{maxWidth: '200px', margin: 0}} value={currBuild} onChange={e => handleSwitchBuild(e.target.value)}>
                          {charCompare.endgameStats.map((curr, index) => {
                            return <option key={curr.display} value={index}>{curr.display}</option>
                          })}
                        </select>
                      </div>
                      {renderBars(charCompare.endgameStats[currBuild])}
                    </div>
                    : 
                    <div style={{alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                      <img alt='' style={{width: '180px', height: '180px', marginTop: '16px'}} src={require('../../assets/not-found.png')}/>
                      <h2>CANNOT RATE YET</h2>
                      <p style={{margin: 0}}>We're finalizing some calculations. Check back soon!</p>
                    </div>
                    }
                  </>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuildCard