import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='footer-container'>
        <div className='footer'>
            <div style={{display: 'flex' ,justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center', rowGap: '16px'}}>
                <div style={{display: 'flex', rowGap: '8px', columnGap: '32px', flexWrap: 'wrap', justifyContent: 'center'}}>
                  {/* <Link className='footer--link' target='_blank' to='https://discord.gg/9WbfzwyF9T'>Discord</Link> */}
                  <Link className='footer--link' to='/contact'>Contact</Link>
                  <Link className='footer--link' target='_blank' to='https://gdonlol.github.io/scoremyrelic-official'>Changelog</Link>
                  <Link className='footer--link' to='/privacy'>Privacy Policy</Link>
                </div>
                <p>2024 © gdonlol</p>
            </div>
            <p style={{maxWidth: '750px', fontSize: '11px'}}>Score My Relic is an independent project and is not affiliated with, endorsed by, or representative of Hoyoverse. All trademarks and registered trademarks pertaining to Hoyoverse and its associated properties are the sole property of Hoyoverse.</p>
        </div>
    </div>
  )
}
