import { useEffect } from 'react'

function Privacy() {

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'Privacy Policy | scoremyrelic'
  }, [])

  return (
    <div className='root' style={{minHeight: '75vh', background: 'none'}}>
        <div className='app'>
            <h2 style={{fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px'}}>🔒 PRIVACY POLICY</h2>
            <div className='main-container'>
                <div className='form-panel' style={{padding: '32px'}}>
                    <p>ScoreMyRelic.com (the "Website") is committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and safeguard your personal information in compliance with the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).</p>
                    
                    <h2>1. Data collection and usage:</h2>

                    <p class="sub-section">1.1 Usage Data: We collect and process usage data regarding your interaction with our website and services. This may
                    include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views,
                    website navigation paths, as well as information about the timing, frequency, and pattern of your service use. Our analytics tracking system
                    is the source of this usage data. We analyze this data to understand and improve the usage of our website and services.</p>

                    <p class="sub-section">1.2 Usernames and passwords: When you register an account on ScoreMyRelic.com, we collect your chosen username and 
                    encrypted password to facilitate login and authentication. This information allows you to securely access and manage your account, ensuring 
                    that only authorized users can interact with the platform. We only store and access one-way encrypted password hashes, ensuring that we never 
                    have access to or store your passwords in plaintext form for enhanced security. Your username and encrypted password are essential for providing 
                    you with a personalized and secure experience on our website.</p>

                    <p class="sub-section">1.3 Honkai: Star Rail User IDs: If you choose to create an account on ScoreMyRelic.com, you are required to provide 
                    your Honkai: Star Rail User ID. This ensures seamless integration with our platform. Your Honkai: Star Rail User ID is essential for accessing 
                    game-related content, tracking your in-game progress, and offering specialized benefits within our community.</p>

                    <p class="sub-section">1.4 Third-Party Sources: In order to furnish you with our services effectively, we may procure information from 
                    third-party sources, such as Hoyoverse's API. This may encompass publicly available player information, such as player statistics and in-game 
                    gear data.</p>

                    <p class="sub-section">1.5 User-Generated Content: We collect user-generated content, including profile pictures and profile banners. These
                    contributions personalize your experience on ScoreMyRelic.com and may be publicly displayed within the community.</p>

                    <h2>2. Lawful Basis for Processing:</h2>
                    <p class="sub-section">2.1 Our processing of personal data is based on the following lawful bases.</p>
                    <p class="sub-section">2.2 Consent: Where you have provided your explicit consent for specific processing activities, such as receiving marketing communications or consenting to the use of cookies.</p>
                    <p class="sub-section">2.3 Contractual Necessity: Processing necessary for the performance of a contract with you, such as providing access to our services and managing your account.</p>
                    <p class="sub-section">2.4 Legal Obligations: Processing necessary to comply with legal obligations to which we are subject, such as responding to legal requests and fulfilling our regulatory obligations.</p>

                    <h2>3. Data Disclosure and Retention:</h2>
                    <p class="sub-section">3.1 Your personal data held in our website database will be stored on the servers of our hosting services providers (Vercel, MongoDB, Amazon Web Services)</p>
                    <p class="sub-section">3.2 Your personal information may be shared with any member of our company as reasonably necessary to achieve the 
                    objectives outlined in this policy. This includes but is not limited to ensuring the smooth operation of our services, maintaining the 
                    security of your data, providing customer support, and improving our products and services.</p>
                    <p class="sub-section">3.3 In exigent circumstances necessitated by legal mandates or valid legal requests, such as subpoenas or court 
                    orders, we may disclose your information in adherence to the applicable legal framework.</p>

                    <h2>4. Data Security:</h2>
                    <p class="sub-section">4.1 We maintain rigorous protocols designed to safeguard your information against unauthorized access, disclosure, alteration, or destruction. These measures encompass the deployment of industry-standard encryption techniques, the implementation of robust access controls, and the routine monitoring of our systems for security vulnerabilities.</p>

                    <h2>5. International Data Transfers:</h2>
                    <p class="sub-section">5.1 Your information may be transmitted to and processed in jurisdictions beyond your domicile, including the United States, where our servers are domiciled. By availing yourself of our Website, you implicitly consent to the cross-border transfer of your information to these jurisdictions. Such transfers adhere to the GDPR requirements for international data transfers.</p>

                    <h2>6. Your Rights:</h2>
                    <p class="sub-section">6.1 You have the right to access, update, or delete your personal information at any time. You can do so by logging into your account or contacting us directly.</p>
                    <p class="sub-section">6.2 Should you opt to abstain from displaying your public records from third-party sources or from us, you may effectuate this choice by directly contacting us.</p>

                    <h2>7. Cookie Policy:</h2>
                    <p class="sub-section">7.1 We use cookies and similar tracking technologies to enhance your experience on ScoreMyRelic.com and to analyze how users interact with our website. You can manage your cookie preferences through your browser settings.</p>

                    <h2>8. Third-Party Links:</h2>
                    <p class="sub-section">8.1 We use cookies and similar tracking technologies to enhance your experience on ScoreMyRelic.com and to analyze how users interact with our website. You can manage your cookie preferences through your browser settings.</p>

                    <h2>9. Changes to This Privacy Policy:</h2>
                    <p class="sub-section">9.1 We reserve the right to update or change our Privacy Policy at any time. Any changes will be posted on this page, and the updated Privacy Policy will be effective immediately upon posting.</p>

                    <p>Last Updated: 05/03/2024</p>
                </div>    
            </div>
        </div>
    </div>
  )
}

export default Privacy