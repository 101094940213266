import React from 'react'
import { InlineMath } from 'react-katex';

function RelicCalcs({relic, setRelicCalcs, chars}) {

  const statops = {
    'SPD': [2.5, null],
    'CRIT Rate': [2, null],
    'ATK%': [1.5, null],
    'HP%': [1.5, null],
    'Effect Hit Rate': [1.5, null],
    'Effect Res': [1.5, null],
    'DEF%': [1.2, null],
    'CRIT DMG': [1, null],
    'Break Effect': [1, null],
    'HP': [1.5, 2271],
    'DEF': [1.2, 988],
    'ATK': [1.5, 1149],
  }

  const currCharData = chars.find(curr => {
    return curr.displayName === relic.basis
  })

  const rendersubs = Object.keys(relic.subs).map((curr, index) => {
    return (
      <p key={`subs${index}`}>{relic.subs[curr][0]}: {relic.subs[curr][4]}</p>
    )
  })

  const renderadjustedsubs = Object.keys(relic.subs).map((curr, index) => {
    const displayStr = relic.subs[curr][0]
    const trueValue = relic.subs[curr][4]
    if (statops[displayStr][1] !== null){
      return (
        <p key={`adjsubs${index}`}>{displayStr} = (({trueValue} / {statops[displayStr][1]}) * 100) * {statops[displayStr][0]} = {((trueValue / statops[displayStr][1]) * 100) * statops[displayStr][0]}</p>
      )
    }
    return (
      <p key={`adjsubs${index}`}>{displayStr} = {trueValue} * {statops[displayStr][0]} = {trueValue * statops[displayStr][0]}</p>
    )
  })

  const weightAdjSubs = Object.keys(relic.subs).map((curr, index) => {
    const displayStr = relic.subs[curr][0]
    const trueValue = relic.subs[curr][4]
    if (statops[displayStr][1] !== null){
      return (
        <span>{((trueValue / statops[displayStr][1]) * 100) * statops[displayStr][0]} * {relic.subs[curr][3]}<br /></span>
      )
    }
    return (
      <span>{trueValue * statops[displayStr][0]} * {relic.subs[curr][3]}<br /></span>
    )
  })

  const subScore = Object.keys(relic.subs).reduce((total, curr) => {
    const displayStr = relic.subs[curr][0]
    const trueValue = relic.subs[curr][4]
    if (statops[displayStr][1] !== null){
      return total + ((((trueValue / statops[displayStr][1]) * 100) * statops[displayStr][0]) * relic.subs[curr][3])
    }
    return total + ((trueValue * statops[displayStr][0]) * relic.subs[curr][3])
  }, 0)

  const renderPenalties = relic.penalties.map((curr, index) => {
    if (curr === null) return null
    return (
      <p style={{color: curr[2] === 0 ? 'green' : 'red'}}>{curr[0]}</p>
    )
  })

  const bonusAmount = relic.penalties.reduce((total, curr) => {
    if (curr === null) return total
    if (curr[2]===0) return total + (5.832 * curr[1])
    else return total 
  }, 0)

  const penaltyFactorNumber = relic.penalties.reduce((total, curr) => {
    if (curr === null) return total
    if (curr[2]!==0) return total + curr[1]
    else return total 
  }, 1)

  const penaltyFactor = relic.penalties.map((curr, index) => {
    if (curr === null) return null
    if (curr[2] !== 0) return (
      <span>{curr[1]} </span>
    )
    return null
  })

  const finalScore = ((Math.floor(subScore * 10) / 10) + bonusAmount) * penaltyFactorNumber

  return (
    <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', position: 'fixed', width: '100%', height: '100%', backgroundColor: '#000000CC', top: 0, left: 0, zIndex: 9999}} >
      <div className='relic-calcs' style={{display: 'inline-block', position: 'relative'}}>
        {/* <h1 style={{fontWeight: 400, marginTop: 0}}>Calculation breakdown</h1> */}
        {/* <button style={{position: 'absolute', top: 0, right: '42px', border: 'none', width: '42px', height: '42px', color: 'white', cursor: 'pointer', background: 'none'}} onClick={() => setRelicCalcs(null)}>✖</button> */}
        
        <div className='relic-calcs-content'>
          <p><b>Relic breakdown</b></p>
          <br/>
          <p>Basis: {relic.basis}</p>
          <table>
            <tbody>
              <tr>
                <td className='relic-calcs-table'>
                  <p>Crit Rate: {currCharData.weightMap.crate}</p>
                  <p>Crit DMG: {currCharData.weightMap.cdmg}</p>
                  <p>SPD: {currCharData.weightMap.spd}</p>
                  <p>ATK%: {currCharData.weightMap['atk-perc']}</p>
                  <p>ATK: {currCharData.weightMap['atk-flat']}</p>
                  <p>HP%: {currCharData.weightMap['hp-perc']}</p>
                </td>
                <td className='relic-calcs-table'>
                  <p>HP: {currCharData.weightMap['hp-flat']}</p>
                  <p>DEF%: {currCharData.weightMap['def-perc']}</p>
                  <p>DEF: {currCharData.weightMap['def-flat']}</p>
                  <p>Break Effect%: {currCharData.weightMap['break']}</p>
                  <p>EHR%: {currCharData.weightMap['ehr']}</p>
                  <p>Eff. RES%: {currCharData.weightMap['res']}</p>
                </td>            
              </tr>
            </tbody>
          </table>
          <br />
        
          <p><b>Substats</b></p>
          <p>1. Get true substat values</p>
          {rendersubs}
          <br />

          <p>2. Adjust values according to basline unit of 6.48</p>
          {renderadjustedsubs}
          <br />
          
          <p>3. Apply weights and sum</p>
          <div style={{display: 'inline-block', borderBottom: '1px solid white'}}>
          {weightAdjSubs}
          </div>
          <p>{subScore} (Truncate to 1 decimal point)</p>
          <p style={{color: '#FFC870'}}>{Math.floor(subScore * 10) / 10} <span style={{color: 'white'}}>(Score of substats only)</span></p>
          <br />
          
          <p><b>Bonuses and penalties</b></p>
          <p>Type: {relic.type} {(relic.type !== 'head' && relic.type !== 'hands') ? '(Bonus available)' : '(No bonus available)'}</p>
          {renderPenalties}
          <br />
          <p>Bonus amount: {bonusAmount}</p>
          <p>Penalty factor: 1 {penaltyFactor}{penaltyFactorNumber !== 1 && <span> = {penaltyFactorNumber}</span>}</p>
          <p>Apply bonuses and penalties: ({Math.floor(subScore * 10) / 10} + {bonusAmount}) * {penaltyFactorNumber} = {finalScore}</p>
          <br/>

          <p><b style={{color: '#FFC870'}}>Final Score: {(Math.floor(finalScore * 10) / 10).toFixed(1)}</b></p>
        </div>
      </div>
      <div className='relic-calcs-border'>

      </div>
      <button style={{background: 'none', border: 'none', color: 'white', marginTop:'16px', fontFamily: 'Anuphan', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setRelicCalcs(null)}>⤺ Back to profile</button>
    </div>
  )
}

export default RelicCalcs