import React from 'react'
import { useState, useEffect } from 'react'

function CharGuide({char, sets}) {

  const [toolTip, setToolTip] = useState('')
  const [showToolTip, setShowToolTip] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [currBuild, setCurrBuild] = useState(0)

  const translator = {
      'crate': 'CRIT Rate',
      'cdmg': 'CRIT DMG',
      'spd': 'SPD',
      'atk-perc': 'ATK%',
      'atk-flat': 'ATK',
      'hp-perc': 'HP%',
      'hp-flat': 'HP',
      'def-perc': 'DEF%',
      'def-flat': 'DEF',
      'break': 'Break%',
      'ehr': 'EHR%',
      'res': 'RES%',
      'healing': 'Healing',
      'physical-dmg': 'Physical%',
      'fire-dmg': 'Fire%',
      'ice-dmg': 'Ice%',
      'wind-dmg': 'Wind%',
      'lightning-dmg': 'Lightning%',
      'quantum-dmg': 'Quantum%',
      'imaginary-dmg': 'Imaginary%',
      'energy': 'Energy'
    }

  const renderStatWeights = Object.keys(char.weightMap)
  .map((curr, index) => {
    if (curr === 'display' || curr === 'id') return null
    return(
      <div key={`subweight${index}`}style={{display: 'flex', justifyContent: 'space-between', width: '100%', backgroundColor: index % 2 !== 0 ? '#00000055' : '', padding: '2px 12px 2px 12px', boxSizing: 'border-box'}}>
        <p style={{margin: 0}}>{translator[curr]}</p>
        <p style={{margin: 0, color: char.weightMap[curr] === 1 ? '#FFE575' : char.weightMap[curr] >= 0.75 ? '#FFB14E' : char.weightMap[curr] >= 0.5 ? '#CF8259' : '#848484'}}>{char.weightMap[curr]}</p>
      </div>
    )
  })

  const renderMainStats = ['body', 'feet', 'sphere', 'rope']

  const handleSwitchBuild = (buildIndex) => {
    setCurrBuild(buildIndex)
  }

  const renderBars = (buildObj) => {

    const translator = {
      spd: 'Speed',
      hp: 'HP',
      def: 'DEF',
      atk: 'ATK',
      break_dmg: 'Break Effect',
      effect_hit: 'Effect Hit Rate',
      effect_res: 'Effect RES',
      crit_dmg: 'CRIT DMG',
      crit_rate: 'CRIT Rate',
      sp_rate: 'Energy Regen',
      heal_rate: 'Outgoing Healing',
      physical_dmg: 'Physical DMG',
      fire_dmg: 'Fire DMG',
      ice_dmg: 'Ice DMG',
      wind_dmg: 'Wind DMG',
      thunder_dmg: 'Lightning DMG',
      quantum_dmg: 'Quantum DMG',
      imaginary_dmg: 'Imaginary DMG',
    }
    //render stat goals
    return Object.keys(buildObj).map((curr, index) => {
      if (curr !== 'display'){
        return (
          <div key={`chareval${buildObj.display}${index}`}>
            <p style={{margin: '12px 0px 12px 0px'}}>{translator[curr]}</p>
            <div
              className='progress-bar'
              style={{
                background: 'linear-gradient(90deg, rgba(125,39,39,1) 0%, rgba(143,135,38,1) 45%, rgba(54,103,45,1) 55%, rgba(54,103,45,1) 100%)',
                width: '100%', height: '8px', position: 'relative', justifyContent: 'center', margin: '4px 0px 16px 0px'}}>
              
              <div className='progress-bar' style={{zIndex: 1, background: 'none', position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>
                <b>|</b>
                <span style={{position: 'absolute', top: '20px', fontSize: '10px'}}>{
                  typeof buildObj[curr] === 'number'
                    ? buildObj[curr] < 5 ? `${(buildObj[curr] * 100).toFixed(1)}%` : buildObj[curr]
                    : buildObj[curr][1] < 5 ? `${(buildObj[curr][1] * 100).toFixed(1)}%` : buildObj[curr][1]
                }</span>
              </div>
              {typeof buildObj[curr] !== 'number' && 
              <div className='progress-bar' style={{zIndex: 1, background: 'none', position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '30%'}}>
                <b>|</b>
                <span style={{position: 'absolute', top: '20px', fontSize: '10px'}}>{buildObj[curr][0] < 5 ? `${(buildObj[curr][0] * 100).toFixed(1)}%` : buildObj[curr][0]}</span>
              </div>
              }
            </div>
          </div>
        )
      }
      return null
    })
  }

  useEffect(() => {
    setCurrBuild(0)
  }, [char])

  return (
    <div className='charguide-container' style={{display: 'flex', position: 'relative', overflow:'hidden', padding: '0', zIndex: 1, backgroundColor: 'black', borderRadius: '8px', flex: 1}}>
      
      {showToolTip && (
        <div style={{
          pointerEvents: 'none',
          position: 'fixed',
          bottom: window.innerHeight - position.y + 6,
          left: position.x,
          transform: 'translateX(-50%)',
          zIndex: 10,
          padding: '8px',
          backgroundColor: '#000000AA',
          borderRadius: '8px',
          backdropFilter: 'blur(4px)'
        }}>
          <p style={{margin: 0}}>{sets.find(curr => curr.id === toolTip).displayName}</p>
        </div>
      )}
      <div style={{ opacity: '50%', zIndex: -1, position: 'absolute', top: 0, left: 0, backgroundColor: 'black', width: '100%', height: '100%'}}/>
      <div style={{display: 'flex', gap: '16px', width: '100%', overflowY:'auto', padding: '20px'}}>

        <div style={{position: 'relative', padding: '8px', borderRadius: '8px', border:'1px solid #FFFFFF11', boxShadow: '1px 1px 11px #00000066', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', minHeight: '450px', minWidth: '300px'}}>
          <img alt='splash art' src={(() => {
            try {
              return require(`../../assets/splash/${char.id}.png`)
            }catch (e) {
              return require(`../../assets/splash/no-texture.png`)
            }
          })()} style={{width: '200%', position: 'absolute', mask: 'linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 40%)'}}/>
          <div style={{position: 'absolute', bottom:'16px', left: '16px'}}>
            <h1 style={{fontSize: '40px',fontStyle: 'italic', textShadow: '5px 0px 0px black', margin: 0}}>{char.displayName}</h1>
            <p style={{textShadow: '2px 2px 4px black'}}>Substat Class: {char.type === 1 ? 'CRIT DPS' : char.type === 2 ?  'DoT' : char.type === 3 ? 'Support' : 'Break DPS'} </p>
          </div>
        </div>

        <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
          <h3 style={{marginTop: 0, minWidth: '250px'}}>Substat Weight</h3>
          <div className='guide-stat-weights'>
            {renderStatWeights}
          </div>

          {char.endgameStats.length > 0 ? 
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <h3>Stat Goals</h3>
              <select className='substat-dropdown' style={{maxWidth: '200px', margin: 0}} value={currBuild} onChange={e => handleSwitchBuild(e.target.value)}>
                {char.endgameStats.map((curr, index) => {
                  return <option key={curr.display} value={index}>{curr.display}</option>
                })}
              </select>
            </div>
            :
            <div style={{alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <img alt='' style={{width: '180px', height: '180px', marginTop: '16px'}} src={require('../../assets/not-found.png')}/>
              <h2>NO BUILDS YET</h2>
              <p style={{margin: 0}}>We're finalizing some calculations. Check back soon!</p>
            </div>
          }

          <div style={{flex: 1, overflow: 'auto', maxHeight: '320px', minHeight: '320px'}}>
            <p>Speed Breakpoints: {char.speedBreakPoints.map((curr, index) => { return (<span style={{color: '#FFE575'}}>{curr === 90 ? 'Base':curr}{index !== char.speedBreakPoints.length - 1 && ", "} </span>)})}</p>
            {char.endgameStats[currBuild] && renderBars(char.endgameStats[currBuild])}
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
          {/* main stats panel */}
          <div className='guide-infographic-panel' style={{width: '340px', minHeight: '155px', maxHeight: '155px', overflowY: 'auto', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(2, 1fr)'}}>
            {renderMainStats.map((curr, index) => {
              return(
                <div key={`mainstatpanel${index}`} style={{height: '80%', padding: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <p style={{margin: 4, color: '#848484', textTransform: 'capitalize'}}>{curr}</p>
                  <div style={{display: 'flex', flexWrap: 'wrap', gap: '0px 8px', justifyContent: 'center', alignItems: 'center'}}>
                    {char[curr].map((currMainstat, index) => {
                      return <b key={`${curr}${index}`} style={{color: currMainstat[1] < 1 ? 'grey' : 'white'}}>{translator[currMainstat[0]]}</b>
                    })}
                  </div>
                </div>
              )
            })} 
          </div>

          {/* sets panel */}
          <div className='guide-infographic-panel' style={{flex: 1, padding: '8px', flexDirection: 'column', alignItems:'flex-start', justifyContent: 'flex-start', width: '340px', maxHeight: '350px', overflow: 'auto'}}>
            <h3 style={{margin: '8px', alignSelf: 'center', fontWeight: 400}}>Useable Sets:</h3>
            <div style={{overflowY: 'auto', display: 'flex', flexWrap: 'wrap', position: 'relative'}}>
              {char.sets.map((curr, index) => {
                return(
                  <div 
                    key={`set${index}`}
                    style={{position: 'relative', display: 'flex', justifyContent: 'center', padding: '4px', cursor: 'pointer'}}
                    onMouseMove={e => setPosition({ x: e.clientX, y: e.clientY })}
                    onMouseEnter={() => {
                      setShowToolTip(true)
                      setToolTip(curr)
                    }}
                    onMouseLeave={ () => setShowToolTip(false) }
                  >
                    <img
                      style={{width: '48px', height: '48px'}}
                      className='image-hover'
                      alt='splash art'
                      src={(() => {
                        try {
                          return require(`../../assets/relics/${curr}-head.png`)
                        }catch (e) {
                          try{
                            return require(`../../assets/relics/${curr}-sphere.png`)
                          }
                          catch(e2){
                            return require(`../../assets/relics/no-texture.png`)
                          }
                        }
                      })()}
                    />
                  </div>
                )
              })}
            </div>           
          </div>

        </div>
        
      </div>
      
    </div>
  )
}

export default CharGuide