import React from 'react'
import { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import dbService from '../services/db-service'
import { Link } from 'react-router-dom'
import CharGuide from '../comps/guides/CharGuide'

function Guides({viewportWidth, mobileSize}) {
  const [sets, setSets] = useState([])
  const [chars, setChars] = useState([])
  const [currChar, setCurrChar] = useState(0)

  const getSetsChars = debounce(async () => {
    const version = await dbService.getVersion()
    if (version){
      setSets(JSON.parse(localStorage.getItem('dbSets')))
      setChars(JSON.parse(localStorage.getItem('dbCharacters')))
    }
    else{
      setSets(await dbService.getSets())
      setChars(await dbService.getChars())
    }
  }, 300)

  const renderChars = chars.map((curr, index) => {
    return (
      <button
        key={`guidechar${index}`}
        className='char-guide-item'
        onClick={() => {setCurrChar(index)}}
      >
        <img alt='Head icon' src={(() => {
          try {
            return require(`../assets/pfp/20${curr.id}.png`)
          }catch (e) {
            return require(`../assets/pfp/no-texture.png`)
          }
        })()} style={{background: curr.rarity === 5 ? 'linear-gradient(to bottom, #74443F, #CBA368)' : 'linear-gradient(to bottom, #5F5682, #B085C8)'}}/>
        
      </button>
    )
  })
  
  useEffect(() => {
      getSetsChars()
      window.scrollTo(0, 0)
      document.title = 'Guides | scoremyrelic'
      // eslint-disable-next-line
  }, [])

  return (
    <div className='root' style={{minHeight: '75vh', background: 'none'}}>
        <div className='app'>
            <h2 style={{fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px'}}>📚 GUIDES & CURRENT META</h2>
            
            <h3 style={{margin: 0}}>Latest changelog</h3>
            <p>Date: 7/30/2024</p>
            <ul>
            <li>Added Duran planar ornament set to Topaz, Herta, Dr. Ratio</li>
            <li>Added The Ashblazing Grand Duke set to Herta</li>
            <li>Added Prisoner in Deep Confinement to many characters who benefit from its +12% ATK</li>
            <li>Lowered weight of Energy Rope for Imbibitor Lunae</li>
            </ul>
            <p style={{marginBottom: 16}}>To propose changes to the current scoring meta, <Link target='_blank'to='https://docs.google.com/forms/d/e/1FAIpQLSesJ8TrD1JWK72VbCAmQYgI_w7-SkOdpBWe1o8VrPj44R6HUA/viewform?usp=sf_link'>click here.</Link></p>
          
            {(chars.length === 0 || sets.length === 0) &&
                <div style={{display: 'flex', flexDirection:'column', alignItems: 'center'}}>
                  <img alt='' style={{width: '100px', height:'auto'}} src={require('../assets/loading.gif')}/>
                  <h2 style={{fontWeight: 300, textAlign: 'center', margin: '4px'}}>Loading</h2>
                </div>
              }
            {chars.length !== 0 && sets.length !== 0 && <CharGuide char={chars[currChar]} sets={sets} />}

            {chars.length !== 0 && sets.length !== 0 && 
              <div className='methods-panel' style={{flexDirection: 'column', gap: '32px', marginTop: 16}}>
                <div className='char-guide-panel'>
                  {renderChars}
                </div>
              </div>
            }

        </div>
    </div>
  )
}

export default Guides