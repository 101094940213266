import axios from 'axios'
const baseUrl=`${process.env.REACT_APP_API_URL}/api/users`
// const baseUrl='http://localhost:3001/api/users'

const getVersion = async () => {
    const response = await axios.get(`${baseUrl}/version`)
    return response.data
}

const getUser = async (givenUid) => {
    const response = await axios.get(`${baseUrl}/info/${givenUid}`)
    return response.data
}

const getUserVersion = async (givenUid) => {
    const response = await axios.get(`${baseUrl}/info/${givenUid}/version`)
    return response.data
}

const searchUser = async (searchParam) => {
    const response = await axios.get(`${baseUrl}/find?searchParam=${searchParam}`)
    return response.data
}

const refreshUser = async (givenUid) => {
    const response = await axios.post(`${baseUrl}`, {uidUpdate: givenUid})
    return response.data
}

const userDbService = {getVersion, getUser, getUserVersion, searchUser, refreshUser}
export default userDbService