import React from 'react'
import CharacterCard from './CharacterCard'

function CharacterSet({chars, charWeightmap, setCharWeightmap, charsetType}) {

  const renderSet = chars
  .filter(curr =>{
    return curr.type.toString() === charsetType
  })
  .map(curr => {
    return(
      <CharacterCard key={curr.id} characterObj={curr} setCharWeightmap={val => setCharWeightmap(val)}/>
    )
  })

  return (
    <div style={{margin:"16px 0px 0px 0px"}}>
        <p style={{margin: 0}}>selected: <b>{charWeightmap.display}</b></p>
        {charsetType !== '4' && <small style={{marginBottom: '8px'}}>off-set: <small style={{color: '#ff6e6e'}}>-25% RS</small>, unwanted main stat: <small style={{color: '#ff6e6e'}}>-50% RS</small></small>}
        {charsetType === '4' && <small style={{marginBottom: '8px'}}>(remember what this category entails and use your own judgement)</small>}
        <div className='charset-container' style={{marginTop:'8px'}}>
          {renderSet}
        </div>
    </div>
  )
}

export default CharacterSet