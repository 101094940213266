import axios from 'axios'
const baseUrl=`${process.env.REACT_APP_API_URL}/api/relicTransaction`
// const baseUrl='http://localhost:3001/api/relicTransaction'

const scoreRelic = async (relicData, weightMap) => {
    const response = await axios.post(`${baseUrl}`, {relicData: relicData, weightMap: weightMap})
    return response.data
}

const relicService = {scoreRelic}
export default relicService