import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

console.log("developed by gordon")
console.warn("Do not run Javascript that you don't trust. You may compromise your account token.")
console.log("If you believe that your token has been compromised, change your password immediately.")

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);