function timeAgo(date) {
    const seconds = Math.floor((Date.now() - new Date(date)) / 1000)

    let interval = Math.floor(seconds / 86400) // Number of seconds in a day
    if (interval >= 1) {
        if (interval >= 31) {
            return "31+ days ago"
        } else {
            return interval + ` day${interval === 1 ? '' : 's'} ago`
        }
    }

    interval = Math.floor(seconds / 3600) // Number of seconds in an hour
    if (interval >= 1) {
        return interval + ` hour${interval === 1 ? '' : 's'} ago`
    }

    interval = Math.floor(seconds / 60) // Number of seconds in a minute
    if (interval >= 1) {
        return interval + ` minute${interval === 1 ? '' : 's'} ago`
    }

    return Math.max(Math.floor(seconds), 0) + ` second${seconds === 1 ? '' : 's'} ago`
  }

  export {timeAgo}