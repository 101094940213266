import axios from 'axios'
const baseUrl=`${process.env.REACT_APP_API_URL}/api/leaderboards`
// const baseUrl='http://localhost:3001/api/leaderboards'

const getVersion = async (lbName) => {
    const response = await axios.get(`${baseUrl}/${lbName}/version`)
    return response.data
}

const lbData = async (lbName, pageNum) => {
    const response = await axios.get(`${baseUrl}/${lbName}${pageNum ? `?page=${pageNum}` : ''}`)
    return response.data
}

const findPlayer = async (lbName, uidToFind) => {
    const response = await axios.get(`${baseUrl}/${lbName}/find/${uidToFind}`)
    return response.data
} 

const lbdbService = {getVersion, lbData, findPlayer}
export default lbdbService