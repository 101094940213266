import React from 'react'

function characterObjCard({characterObj, setCharWeightmap}) {

  return (
    <button className="charset-char" style={characterObj.rarity === 5 
      ? {background: 'linear-gradient(to bottom, #74443F, #CBA368)'}
      : {background: 'linear-gradient(to bottom, #5F5682, #B085C8)'}}
      onClick={() => {
        setCharWeightmap({
          ...characterObj.weightMap,
          display: characterObj.displayName,
          mainStats: {
            sets: characterObj.sets,
            body: characterObj.body,
            feet: characterObj.feet,
            sphere: characterObj.sphere,
            rope: characterObj.rope,
          }
        })
      }
    }>
        <img src={(() => {
          try {
            return require(`../../assets/pfp/20${characterObj.id}.png`);
          } catch (e) {
            return require(`../../assets/pfp/no-texture.png`);
          }
        })()} alt='head-icon' />
    </button>
  )
}

export default characterObjCard