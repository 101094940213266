import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import authService from '../services/auth-service'

function Login({viewportWidth, mobileSize}) {

    //input states
    const [reqPayload, setReqPayload] = useState({
        username: '',
        password: ''
    })
    const [showMsg, setShowMsg] = useState(false)
    const [msgText, setMsgText] = useState('Error')

    const handleLogin = async (e) => {
        e.preventDefault()
        try{
            const {token} = await authService.login(reqPayload)
            localStorage.setItem('token', token)
            window.location.href = "/dashboard"
        }catch(err){
            setMsgText(err.response.data.error)
            setShowMsg(true)
        }        
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Login | scoremyrelic'
    }, [])

  return (
    <div className="root" style={{minHeight: '75vh', background: 'none', justifyContent: 'center'}}>
        <div className='app' style={{display: 'flex', justifyContent: 'center'}}>
            <div className='main-container' style={{width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px', boxSizing: 'border-box'}}>
                <h1 style={{fontWeight: 400, textAlign: 'center', width: '100%', borderBottom: '1px solid #484239', paddingBottom: '16px', marginBottom: '0'}}>Log In</h1>
                <form onSubmit={e => handleLogin(e)} className='auth-panel' style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <label>Username</label>
                    <input value={reqPayload.username} onChange={e => setReqPayload(old => {return ({...old, username: e.target.value})})}></input>
                    <label>Password</label>
                    <input type='password' value={reqPayload.password} onChange={e => setReqPayload(old => {return ({...old, password: e.target.value})})}></input>
                    <button className='default-btn' style={{marginRight: '0px', width: '150px', alignSelf: 'center'}}>Log In</button>
                </form>

                {showMsg && <div style={{marginBottom: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', backgroundColor: '#d63a3a44', border: '1px solid #d63a3a', borderRadius: '8px', padding: '8px', boxSizing: 'border-box'}}>
                    <p style={{margin: 0}}>{msgText}</p>
                </div>}

                <div style={{borderTop: '1px solid #484239', paddingTop: '16px', width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Link style={{color: 'white', textDecoration: 'none'}} to="/sign-up">Create an account</Link>
                    {/* <Link style={{color: 'white', textDecoration: 'none'}} to="/reset-password">Forgot password?</Link> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login