import React from 'react'

function MethodsPanel({appMethod, setAppMethod}) {
  return (
    <div className='methods-panel'>
      <div className='methods-btns'>
      <button className='default-btn'style={{width: "100%", margin: "8px 0px 8px 0px"}} onClick={() => setAppMethod(0)}>🔎 Hoyoverse UID & Name Search</button>
      <button className='default-btn' style={{width: "100%", margin: "8px 0px 8px 0px"}} onClick={() => setAppMethod(1)}>👨‍💻 Manual Input</button>
      <button className='default-btn' style={{width: "100%", margin: "8px 0px 8px 0px"}} onClick={() => setAppMethod(2)}>📸 Screenshot Scanner</button>
      </div>
      <div className='methods-desc'>
      {appMethod === 0 && <b>Hoyoverse UID & Name Search</b>} {appMethod === 1 && <b>Manual Input</b>} {appMethod === 2 && <b>Screenshot Scanner</b>} 
      {appMethod === 0 && <ul>
          <li>📋 paste UID or look up a previously indexed UID's username</li>
          <li>📊 scores entire inventory</li>
          <li>⚡ no need for manual input = lightning fast</li>
          <li>⏳ must wait for hoyo servers to update upon changes to in-game relics</li>
      </ul>}
      {appMethod === 1 && <ul>
          <li>🕗 no need to wait for hoyo servers to update your public data</li>
          <li>💡 provides recommendations for relic</li>
          <li>✍️ requires manual input</li>
          <li>🔂 scores relics one at a time</li>
      </ul>}
      {appMethod === 2 && <p>coming soon...</p>}
      </div>
    </div>
  )
}

export default MethodsPanel