import axios from 'axios'
const baseUrl=`${process.env.REACT_APP_API_URL}/api/relicdb`
// const baseUrl='http://localhost:3001/api/relicdb'

const getVersion = async () => {
    const response = (await axios.get(`${baseUrl}/version`)).data
    if (localStorage.getItem('dbversion') && parseInt(localStorage.getItem('dbversion')) === response.dbversion){
        return true
    }
    localStorage.setItem('dbversion', response.dbversion)
    return false
}

const getSets = async () => {
    const response = await axios.get(`${baseUrl}/sets`)
    localStorage.setItem('dbSets', JSON.stringify(response.data))
    return response.data
}

const getChars = async () => {
    const response = await axios.get(`${baseUrl}/characters`)
    localStorage.setItem('dbCharacters', JSON.stringify(response.data))
    return response.data
}

const getWeightmap = async (id) => {
    const response = await axios.get(`${baseUrl}/weightmaps/${id}`)
    return response.data
}

const getClass = async (id) => {
    const response = await axios.get(`${baseUrl}/class/${id}`)
    return response.data
}

const dbService = {getVersion, getSets, getWeightmap, getChars, getClass}
export default dbService