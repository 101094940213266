import React from 'react'
import { useState, useEffect } from 'react'

function RefreshCooldown({handleUserRefresh, lastUpdated,loading}) {

    const [refreshcd, setRefreshcd] = useState(Date.now() - new Date(lastUpdated))
    const cooldownTime = 150000;

    useEffect(() => {
        if (refreshcd <= cooldownTime) {
            setTimeout(() => {
                setRefreshcd(Date.now() - new Date(lastUpdated))
            }, 1000)
        }
        // eslint-disable-next-line
    }, [refreshcd, cooldownTime])


    return (
        <button onClick={(refreshcd <= cooldownTime || loading) ? () => {} : () => handleUserRefresh() } className='default-btn'
        style={(refreshcd <= cooldownTime || loading)
            ? {width: '100%',margin: '4px', cursor: loading ? 'wait' : 'not-allowed', backgroundColor: 'white', color: 'black', border: 'none'}
            : {width: '100%', margin: '4px'}
        }>
            {loading
                ? <span>Loading...</span>
                : <span>{refreshcd <= cooldownTime ? `${Math.floor(150-(refreshcd/1000))}s` : 'Refresh profile ⟳'}</span>
            }
        </button>
    )
}

export default RefreshCooldown