import React from 'react'
import { getSignature } from '../../utils/signatureParse';

function SearchedUser({user, formHandler, viewportWidth, mobileSize}) {

  const signature = getSignature(user.signature)

  return (
    <div className='searched-user'>
        <div className='searched-user--inner'>
            <img alt='Profile icon' src={(() => {
              try {
                return require(`../../assets/pfp/${user.pfp}.png`);
              } catch (e) {
                return require(`../../assets/pfp/no-texture.png`);
              }
            })()}/>
            <div style={{flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <p style={{margin: 0, color: '#000'}}>{user.displayName}</p>
            <hr style={{color: 'black', width: '100%', margin: 0, mask: 'linear-gradient(to right, rgba(0,0,0,1) 55%, rgba(0,0,0,0))', WebkitMask: 'linear-gradient(to right, rgba(0,0,0,1) 55%, rgba(0,0,0,0))'}}/>
            <i style={{fontSize: '11px', color: signature.color}}>"{signature.text}"</i><br/>
            <small style={{fontSize: '9px'}}>UID: {user.uid}</small>
            </div>
            <button onClick={() => formHandler.viewUser(user.uid)} className="default-btn" style={{padding: '0px 16px 0px 16px', margin: '0px 16px 0px 16px'}}>{viewportWidth >= mobileSize ? 'View →' : '→'}</button>
            
        </div>
    </div>
  )
}

export default SearchedUser