import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { profileInvValueColor, profileRsColor } from '../utils/scoreStyle'
import userDbService from '../services/userdb-service'
import inUserService from '../services/in-user-service'
import dbService from '../services/db-service';
import ProfileBanner from '../comps/profile/ProfileBanner'
import MobileProfileBanner from '../comps/profile/MobileProfileBanner'
import BuildCard from '../comps/profile/BuildCard'
import BuildCardMobile from '../comps/profile/BuildCardMobile'
import ScoredInventory from '../comps/inv-display/ScoredInventory'

function UserProfile({viewportWidth, mobileSize}) {
  const MAX_CACHE_LENGTH = 30
  const { userId, vanityParam } = useParams()
  const [user, setUser] = useState(null)
  const [version, setVersion] = useState(null)
  const [charViewing, setCharViewing] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const relicList = user !== null ? user.charList.flatMap((curr) => curr.relicList) : []
  const [sets, setSets] = useState([])
  const [chars, setChars] = useState([])
  const [settings, setSettings] = useState(JSON.parse(localStorage.getItem('settings')) ?? {
    bgType: 0,
    acceptAllMainStats: false,
    acceptAllSets: false,
    acceptOffSets: false
  })
  const [moddedChar, setModdedChar] = useState(null)

  const getUser = debounce(async (searchUid) => {
    const uidVersion = await userDbService.getVersion()
    setVersion(uidVersion)
    if (localStorage.getItem('uidCacheVersion') !== uidVersion.toString()) {
      localStorage.removeItem('uidCache')
      localStorage.setItem('uidCacheVersion', uidVersion)
    }

    let uidCache = JSON.parse(localStorage.getItem('uidCache'))
    if (!uidCache) uidCache = []
    //filter any nulls (idk how null happens LOL)
    uidCache = uidCache.filter(item => item !== null)

    const foundUid = uidCache.findIndex(curr => curr.uid === parseInt(searchUid)) 
    try{
      let userData = foundUid !== -1 ? uidCache[foundUid] : await userDbService.getUser(searchUid)

      if (foundUid !== -1 && userData.lastUpdated !== await userDbService.getUserVersion(searchUid)){
        userData = await userDbService.getUser(searchUid)
      }

      setUser(userData)
      uidCache.unshift(userData)
      if (foundUid !== -1) {
        uidCache.splice(foundUid + 1, 1)
      }
      if (uidCache.length > MAX_CACHE_LENGTH) uidCache.pop()
    }catch (e){
      setUser(null)
      setIsLoading(false)
      uidCache.splice(foundUid, 1)
    }

    localStorage.setItem('uidCache', JSON.stringify(uidCache))
    setIsLoading(false)
  }, 300)

  const renderChars = user !== null 
  ? user.charList.map((curr, index) => {
    return (
      <div key={`charbtn${index}`}style={{position: 'relative'}}>
        <p style={{position: 'absolute', bottom: 0, right: 0, margin: 0, fontSize: '10px', backgroundColor: '#00000099', padding: '0px 2px 0px 2px'}}>{curr.avgRs} <span style={{color: 'grey'}}>ARS</span></p>
        <button style={{padding: 0, width: '56px', height: '56px', boxShadow: `-5px 0px 2px ${curr.rarity === 5 ? '#E2AC58' : '#7242B3'}`, borderRadius: '99px', overflow: 'hidden', cursor: 'pointer', backgroundColor: '#000', border: 'none'}} onClick={() => {setCharViewing(index)}}>
          <img alt='' src={(() => {
            try {
              return require(`../assets/pfp/20${curr.id}.png`);
            } catch (e) {
              return require(`../assets/pfp/no-texture.png`);
            }
          })()} style={{height: '100%', width: '100%', pointerEvents: 'none'}}/>  
        </button>
      </div>
    )
  })
  : <p></p>

  useEffect(() => {
    if (!user) return
    if (!settings.acceptAllMainStats && !settings.acceptAllSets && !settings.acceptOffSets) return setModdedChar(null)
    const newChar = JSON.parse(JSON.stringify(user.charList[charViewing]));

    newChar.relicList = newChar.relicList.map((currRelic) => {

      if (currRelic.penalties.length > 0){
        //reverse sccore to og
        const oldFactor = currRelic.penalties.reduce((total, curr) => {
          if (curr === null) return total
          if (curr[2] !== 0) return total + curr[1]
          return total
        }, 1)
        
        //new penalties
        let newPenalty = oldFactor
        let bonusRs = 5.832
        currRelic.penalties.forEach((currPenalty, index) => {
          if (currPenalty[2] === 0 && currPenalty[1] === 1){
            bonusRs = 0
          }else if (currPenalty[2] === 0 && (currPenalty[1] < 1 && currPenalty[1] > 0) && settings.acceptAllMainStats){
            bonusRs -= bonusRs * currPenalty[1]
          }
          else if (currPenalty[2] === 3 && settings.acceptAllSets){
            newPenalty -= currPenalty[1]
            currRelic.penalties[index] = null
          }else if (currPenalty[2] === 2 && settings.acceptOffSets){
            newPenalty -= currPenalty[1]  
            currRelic.penalties[index] = null
          }else if (currPenalty[2] === 1 && settings.acceptAllMainStats){
            newPenalty -= currPenalty[1]
            currRelic.penalties[index] = null
          }
        })
        if (newPenalty <= 0) return currRelic
        if (currRelic.type === 'head' || currRelic.type === 'hands' || !settings.acceptAllMainStats) bonusRs = 0
        if (bonusRs > 0) currRelic.penalties.push([`Main stat bonus: +${bonusRs} RS`, bonusRs/5.832, 0])
        return {...currRelic, score: Math.max(Math.min( (((currRelic.score / oldFactor) + bonusRs) * newPenalty ), 58.3), 0)}
      }
      return currRelic
    })
    newChar.totalRs = newChar.relicList.reduce((accum, curr) => accum + curr.score, 0)
    newChar.avgRs = newChar.relicList.length > 0 ? newChar.totalRs/newChar.relicList.length : 0
    setModdedChar(newChar)
  }, [settings, user, charViewing]) 
  
  useEffect(() => {
    if (!vanityParam) {getUser(userId)}
    else {
      const vanitySetup = async () => {
        try{
          getUser(await inUserService.getUidFromVanity(vanityParam)) 
        }
        catch(e){
          setUser(null)
          setIsLoading(false)
        }
      }
      vanitySetup()
    }
    // eslint-disable-next-line
  }, [userId])
  
  useEffect(() => {
    document.title = user ? `${user.displayName} | Stats overview` : 'Loading profile'
  }, [user])

  useEffect(() => {
    window.scrollTo(0, 0)
    const getSetsChars = debounce(async () => {
      const version = await dbService.getVersion()
      if (version){
        setSets(JSON.parse(localStorage.getItem('dbSets')))
        setChars(JSON.parse(localStorage.getItem('dbCharacters')))
      }
      else{
        setSets(await dbService.getSets())
        setChars(await dbService.getChars())
      }
      
    }, 1000)

    getSetsChars()
  }, [])

  if (user === null){
    return(
      <div style={{height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '64px'}}>
      { isLoading === false &&
      <>
        <img alt='' src={require('../assets/not-found.png')} style={{width: '128px'}}/>
        <h1 style={{textAlign: 'center'}}>404 NOT FOUND</h1>
        <p style={{textAlign: 'center'}}>User does not exist in our database. Check your input and try again.</p>
      </>
      }
      </div>
    )
  }
  return (
    <div className="root" style={{backgroundImage: 'none', position: 'relative'}}>
      
      <div className='profile-container'>

        {!(viewportWidth < mobileSize) && <ProfileBanner user={user} profileVersion={version} />}
        {(viewportWidth < mobileSize) && <MobileProfileBanner user={user} profileVersion={version}/>}
        
        <div className='profile-panel'>
          <h3 style={{margin: '0px 0px 8px 0px', fontWeight: 300, fontSize: '20px'}}>{user.displayName}'s Public Characters</h3>
          <p style={{color: '#818181', fontStyle: 'italic', margin: '0px 0px 8px 0px', fontSize: '11px'}}>UID: {user.uid}</p>
            <div style={{display: 'flex', gap: '16px', padding: '0px 0px 8px 8px', overflowX : 'auto'}}>
              {renderChars}
            </div>
            {user.charList.length > 0 && chars && sets && chars.length > 0 && sets.length > 0 && !(viewportWidth < mobileSize) && <BuildCard charIn={moddedChar === null ? user.charList[charViewing] : moddedChar} isModded={moddedChar === null ? false : true} user={user} sets={sets} chars={chars} bgType={settings.bgType}/>}
            {user.charList.length > 0 && chars && sets && chars.length > 0 && sets.length > 0 && viewportWidth < mobileSize && <BuildCardMobile charIn={moddedChar === null ? user.charList[charViewing] : moddedChar} isModded={moddedChar === null ? false : true} user={user} sets={sets} chars={chars} bgType={settings.bgType}/>}
            <div style={{marginTop: '16px', display: 'flex', alignItems: 'center', columnGap: '32px', rowGap: '8px', flexWrap: 'wrap'}}>
              <div style={{minWidth: '175px'}}>
                <select className='substat-dropdown' style={{maxWidth: '200px', margin: '0px 4px 0px 0px'}} value={settings.bgType} 
                  onChange={e => {
                    setSettings(old => {return ({...old, bgType: Number(e.target.value)})})
                    localStorage.setItem('settings', JSON.stringify({...settings, bgType: Number(e.target.value)}))
                  }}>
                  <option value={0}>Default</option>
                  <option value={1}>Character</option>
                  <option value={2}>None</option>
                </select>
                <label> Background </label>
              </div>

              <div style={{minWidth: '130px'}}>
                <input id='aams' type='checkbox' className='hidden-checkbox' checked={settings.acceptAllMainStats}
                onChange={ e => {
                  setSettings(old => {return ({...old, acceptAllMainStats: e.target.checked}) } )
                  localStorage.setItem('settings', JSON.stringify({...settings, acceptAllMainStats: e.target.checked}))
                } }></input>
                <label htmlFor='aams' className='default-checkbox'></label>
                <label htmlFor='aams' style={{cursor: 'pointer', userSelect: 'none'}}> Accept All Main Stats *</label>
              </div>

              <div style={{minWidth: '130px'}}>
                <input id='aas' type='checkbox' className='hidden-checkbox' checked={settings.acceptAllSets}
                onChange={ e => {
                  setSettings(old => {return ({...old, acceptAllSets: e.target.checked}) } )
                  localStorage.setItem('settings', JSON.stringify({...settings, acceptAllSets: e.target.checked}))
                } }></input>
                <label htmlFor='aas' className='default-checkbox'></label>
                <label htmlFor='aas' style={{cursor: 'pointer', userSelect: 'none'}}> Accept All Sets *</label>
              </div>

              <div style={{minWidth: '130px'}}>
                <input id='aosp' type='checkbox' className='hidden-checkbox' checked={settings.acceptOffSets}
                onChange={ e => {
                  setSettings(old => {return ({...old, acceptOffSets: e.target.checked}) } )
                  localStorage.setItem('settings', JSON.stringify({...settings, acceptOffSets: e.target.checked}))
                } }></input>
                <label htmlFor='aosp' className='default-checkbox'></label>
                <label htmlFor='aosp' style={{cursor: 'pointer', userSelect: 'none'}}> Accept Off-set Pieces </label>
              </div>
              
            </div>
            <p style={{marginBottom: 0, color: '#848484'}}><i>* Recommended for specialized/niche builds.</i></p>
            <p style={{margin: 0, color: '#848484', fontSize: '10px'}}><i>Note: There might be slight fluctuations in accuracy, particularly at the decimal level.</i></p>
        </div>

        <div className='profile-panel'>
          <div style={{padding: '0px 0px 12px 0px'}}>
            <h3 style={{margin: '0px 0px 8px 0px', fontWeight: 300, fontSize: '20px'}}>Public Inventory</h3>
            <p style={{color: '#818181', fontStyle: 'italic', margin: '0px 0px 8px 0px', fontSize: '11px'}}>UID: {user.uid}</p>
            <div style={{display: 'flex'}}>
              <div className='profile-stat-box'>
                <p style={{margin: 0, color: '#818181'}}>Average RS:</p>
                <b style={{margin: 0, fontSize: '18px', color: `${profileRsColor( (user.invScore/relicList.length) )}`}}>{(user.invScore/(relicList.length > 0 ? relicList.length : 1)).toFixed(1)}</b>
              </div>
              <div className='profile-stat-box'>
                <p style={{margin: 0, color: '#818181'}}>Total Value:</p>
                <b style={{margin: 0, fontSize: '18px', color: `${profileInvValueColor(user.invScore)}`}}>{Math.floor(user.invScore * 10) / 10}</b>
              </div>
              <div className='profile-stat-box'>
                <p style={{margin: 0, color: '#818181'}}>Item count: </p>
                <p style={{margin: 0, fontSize: '12px'}}><b style={{margin: 0, fontSize: '18px'}}>{relicList.length}</b></p>
              </div>
            </div>
          </div>

          <ScoredInventory currInventory={user} viewportWidth={viewportWidth} mobileSize={mobileSize} hideBanner={true}/>

        </div>

      </div>
    </div>
  )
}

export default UserProfile