const extractColor = (signature) => {
    const colorRegex = /#(?:[a-fA-F0-9]{6}|[a-fA-F0-9]{3}|[a-zA-Z]+)/
    const match = signature.match(colorRegex)
    return match ? match[0] : null
  }

  const removeColorTag = (signature) => {
    return signature.replace(/<\/?cŏlor(?:=[^>]+)?>/g, '')
  }

  const getSignature = (signature) => {
    const signatureColor = extractColor(signature ? signature : "")
    const parsedSignature = removeColorTag(signature ? signature : "")
    return {color: signatureColor, text: parsedSignature}
  }

  export{getSignature}