import React from 'react'
import RVDisplay from '../RVDisplay'
import { useState } from 'react'
import UnitRSDisplay from './UnitRSDisplay'
import { findColor, findRank } from '../../utils/scoreStyle'

function ScoredRelic({scoredRelic}) {

    const [backgroundOffset, setBackgroundOffset] = useState(0)

    const handleMouseMove = (e) => {
      const mouseX = e.clientX / window.innerWidth
      setBackgroundOffset(mouseX * 0.1)
    }

    if (scoredRelic === null){
        return(
            
            <div className='score-panel' style={{backgroundImage: 'none'}}>
                <p>waiting for data...</p>
            </div>
        )
    }

    const renderSubs = scoredRelic.subs.map((curr, index) => {
        return (
            <RVDisplay key={index} sub={curr} index={index} />
        )
    })

    return (
        <div className='score-panel' onMouseMove={handleMouseMove} style={scoredRelic === null ? {} : { backgroundPosition: `${backgroundOffset * 100}% center`}}>
            <div className='score-display' style={{boxShadow: `0 0 20px 0px ${findColor(scoredRelic.totalScore)}`}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                    <div className='relic-img' alt='' style={{margin: '0px 16px 0px 0px'}}>
                        <p>{scoredRelic.valid ? "":"⚠️"}</p>
                        <img alt='' src={(() => {
                            try {
                                return require(`../../assets/relics/${scoredRelic.iconPath}`);
                            } catch (e) {
                                return require(`../../assets/relics/no-texture.png`);
                            }
                        })()}/>
                    </div>
                    <div>
                        <p style={{margin: '0px 0px 8px 0px', fontSize: '14px', color: '#FFF'}}>{scoredRelic.mainData.statDisplay}</p>
                        <div style={{display: 'flex'}}>
                            <div style={{marginRight: '20px'}}>
                                <p style={{margin: 0, fontSize: '14px'}}>Score: </p>
                                <b style={{margin: 0, fontSize: '22px', color: findColor(scoredRelic.totalScore)}}>{scoredRelic.totalScore} </b>
                            </div>
                            <div>
                                <p style={{margin: 0, fontSize: '14px'}}>Rank: </p> 
                                <b style={{margin: 0, fontSize: '22px', color: findColor(scoredRelic.totalScore)}}>{findRank(scoredRelic.totalScore)} </b>
                            </div>
                        </div>
                    </div>
                </div>
                {renderSubs}
                <p>Score Basis: <b>{scoredRelic.basis}</b></p>
                <hr />
                <div>
                    <p>Top Class: <b>{scoredRelic.topClass[0]}</b></p>
                    <p style={{marginBottom: '4px'}}>Top Users:</p>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <UnitRSDisplay userArr={scoredRelic.topUsers[0]} />
                        <UnitRSDisplay userArr={scoredRelic.topUsers[1]} />
                        <UnitRSDisplay userArr={scoredRelic.topUsers[2]} />
                    </div>
                </div>
                <div style={{display: 'flex', marginTop: '16px'}}>
                    <div style={{marginRight: '8px'}}>
                        <p style={{margin: 0, fontSize: '14px'}}>Rolls: </p>
                        <b style={{margin: 0, fontSize: '16px'}}>{scoredRelic.rolls === null ? 'N/A' : scoredRelic.rolls} </b>
                    </div>
                    <div style={{marginRight: '8px'}}>
                        <p style={{margin: 0, fontSize: '14px'}}>Effective Stats: </p> 
                        <b style={{margin: 0, fontSize: '16px'}}>{scoredRelic.effStats} </b>
                    </div>
                </div>
                <p>Valid In-game: {scoredRelic.valid ? "✅":"❌"}</p>
            </div>
        </div>
        
    )
}

export default ScoredRelic