import React from 'react'
import SubstatForm from './SubstatForm'
import BasisForm from './BasisForm'
import ScoredRelic from './ScoredRelic'
import dbService from '../../services/db-service'
import relicService from '../../services/relic-service'

const ManualInputForm = ({sets, chars, relic, setRelic, scoredRelic, setScoredRelic, basis, setBasis, charsetType, setCharsetType, charWeightmap, setCharWeightmap, customWeightMap, setCustomWeightMap}) => {

    const formHandler = {
        submitStats: async (e) => {
        e.preventDefault()
        try{
            if (basis === 'custom'){
            setScoredRelic(await relicService.scoreRelic(relic, {...customWeightMap, display: 'Custom Criteria'}))
            }
            else if (basis === 'character'){
            setScoredRelic(await relicService.scoreRelic(relic, charWeightmap)) //mainstat data is supplied alerady
            }
            else if (basis === 'leaked-character'){
            setScoredRelic(await relicService.scoreRelic(relic, customWeightMap))
            }
            else{ 
            const weightmap = await dbService.getWeightmap(basis)
            const basisClass = await dbService.getClass(basis)
            setScoredRelic(await relicService.scoreRelic(relic, {
                ...weightmap, 
                mainStats: {
                sets: basisClass.sets,
                body: basisClass.body,
                feet: basisClass.feet,
                sphere: basisClass.sphere,
                rope: basisClass.rope,
                ignore: basisClass.ignore ? basisClass.ignore : false
                }
            }))
            }
            }catch(e) {
            alert(e.response.data.error)
        }
        },
        changeSet: async (e) => {
        const id = e.target.value
        const type = sets.find(curr => {
            return curr.id === id
        }).type
        setRelic(old => {
            if (type !== old.set[1]){
            if (type === 0) {return ({...old, set: [id, type], mainstat: 'hp-flat', type: 'head'})}
            else return ({...old, set: [id, type], mainstat: 'atk-perc', type: 'sphere'})
            }
            return ({...old, set: [id, type]})
        })
        },
        changeType: (e) => {
        setRelic(old => {
            const mainStatMap = {
            'head': 'hp-flat',
            'hands': 'atk-flat',
            'body': 'crate',
            'feet': 'spd',
            'sphere': 'atk-perc',
            'rope': 'atk-perc'
            }
            const mainStat = mainStatMap[e.target.value]
            if (mainStat) {
            return { ...old, type: e.target.value, mainstat: mainStat }
            }
        })
        },
        changeMainstat: (e) => {
        setRelic(old => ({...old, mainstat: e.target.value}))
        },
        changeSubstat: (e, subnum) => {
        setRelic(old => ({...old, [`sub${subnum}`]: [e.target.value, 0]}))
        },
        changeSubvalue: (e, subnum) => {
        setRelic(old => ({...old, [`sub${subnum}`]: [old[`sub${subnum}`][0], e.target.value]}))
        },
        invalidInput: (e) => {
        e.preventDefault()
        alert(`Invalid input "${e.target.value}"`)
        }
    }
    const setOptions = sets.map(curr => {
        return <option key={curr.id} value={curr.id}>{curr.displayName}</option>
    })
    
    return (
    <div className='main-container'>
        <div className="form-panel">
            <h3>Manual Input</h3>
            <form className='relic-form' onSubmit={e => formHandler.submitStats(e)}>
                <div className="form-section" style={{display: 'flex', flexDirection: 'column', marginBottom: '16px'}}>
                    <b>main stats:</b>
                    <small>desired non-fixed main stat: <small style={{color: '#32a852'}}>+5.832 RS</small></small>

                    <div style={{display: 'flex', marginTop: '16px', alignItems: 'center'}}>
                        <select className='substat-dropdown' style={{maxWidth: '200px'}} value={relic.set[0]} onChange={e => formHandler.changeSet(e)}>
                            {setOptions}
                        </select>
                    </div>
                    <div>
                        <select className='substat-dropdown' style={{width: '200px'}} value={relic.type} onChange={e => formHandler.changeType(e)} >
                        {relic.set[1] === 0 && (
                            <>
                                <option value='head'>Head</option>
                                <option value='hands'>Hands</option>
                                <option value='body'>Body</option>
                                <option value='feet'>Feet</option>
                            </>
                        )}
                        {relic.set[1] === 1 && (
                            <>
                                <option value='sphere'>Sphere</option>
                                <option value='rope'>Rope</option>
                            </>
                        )}
                        </select>
                    </div>
                    <div >
                        <select className='substat-dropdown' style={{width: '200px'}} value={relic.mainstat} onChange={e => formHandler.changeMainstat(e)} >
                        {relic.type === 'head' && <option value='hp-flat'>HP</option>}
                        {relic.type === 'hands' && <option value='atk-flat'>ATK</option>}
                        {!(relic.type === 'head' || relic.type === 'hands') && (
                            <>
                                <option value='hp-perc'>HP%</option>
                                <option value='atk-perc'>ATK%</option>
                                <option value='def-perc'>DEF%</option>
                            </>
                        )}
                        {relic.type === 'body' && (
                            <>
                                <option value='crate'>CRIT RATE</option>
                                <option value='cdmg'>CRIT DMG</option>
                                <option value='healing'>Outgoing Healing</option>
                                <option value='ehr'>Effect HIT Rate</option>
                            </>
                        )}
                        {relic.type === 'feet' && <option value='spd'>SPD</option>}
                        {relic.type === 'sphere' && (
                            <>
                                <option value='physical-dmg'>Physical DMG</option>
                                <option value='fire-dmg'>Fire DMG</option>
                                <option value='ice-dmg'>Ice DMG</option>
                                <option value='lightning-dmg'>Lightning DMG</option>
                                <option value='wind-dmg'>Wind DMG</option>
                                <option value='quantum-dmg'>Quantum DMG</option>
                                <option value='imaginary-dmg'>Imaginary DMG</option>
                            </>
                        )}
                        {relic.type === 'rope' && (
                            <>
                                <option value='break'>Break Effect</option>
                                <option value='energy'>Energy Regen Rate</option>
                            </>
                        )}
                        </select>
                    </div>
                    <div className='relic-img'>
                    <img alt='' src={(() => {
                        try {
                            return require(`../../assets/relics/${relic.set[0]}-${relic.type}.png`);
                        } catch (e) {
                            return require(`../../assets/relics/no-texture.png`);
                        }
                    })()}/>
                    </div>
                    
                </div>
                <div className="form-section, form-section-subs" style={{display: 'flex', flexDirection: 'column'}}>
                    <b>substats:</b>
                    <small>(order does not matter)</small>
                    <div style={{marginTop: '16px'}}>
                        <SubstatForm relic={relic} formHandler={formHandler} subnum={1}/>
                        <SubstatForm relic={relic} formHandler={formHandler} subnum={2}/>
                        <SubstatForm relic={relic} formHandler={formHandler} subnum={3}/>
                        <SubstatForm relic={relic} formHandler={formHandler} subnum={4}/>
                    </div>
                    <button className='default-btn' style={{width: '255px', marginTop: '16px'}}>🎯 Score it</button>
                </div>
            </form>
            <BasisForm 
                chars={chars}
                basis={basis}
                setBasis={val => setBasis(val)}
                setCharWeightmap={val => setCharWeightmap(val)}
                charWeightmap={charWeightmap}
                charsetType={charsetType}
                setCharsetType={val => setCharsetType(val)}
                customWeightMap={customWeightMap}
                setCustomWeightMap={val => setCustomWeightMap(val)}
            />
        </div>
        <ScoredRelic scoredRelic={scoredRelic} />
    </div>
    )
}

export default ManualInputForm