import './App.css'
import 'katex/dist/katex.min.css'
import { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import inUserService from './services/in-user-service'
import Header from './comps/Header'
import MobileMenu from './comps/MobileMenu'
import RelicScorer from './pages/RelicScorer'
import Home from './pages/Home'
import Leaderboards from './pages/Leaderboards'
import Footer from './comps/Footer'
import UserProfile from './pages/UserProfile'
import Search from './pages/Search'
import Contribute from './pages/Contribute'
import Privacy from './pages/Privacy'
import Contact from './pages/Contact'
import Guides from './pages/Guides'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import Dashboard from './pages/Dashboard'
import DashboardMobile from './pages/DashboardMobile'

const App = () => {

  const mobileSize = 1000 //in px
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
  const [user, setUser] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)

  useEffect(() => {
    //listener for mobile ui
    window.addEventListener('resize', () => setViewportWidth(window.innerWidth))

    //authenticate token
    const setUserWithToken = async (theToken) => {
      if (!theToken || theToken === '') return setLoadingUser(false)
      try{  
        setUser(await inUserService.getUser(theToken))
        setLoadingUser(false)
      }
      catch(e){
        localStorage.removeItem('token')
        setUser(null)
        setLoadingUser(false)
      }
    }
    setUserWithToken(localStorage.getItem('token'))
    
  }, [])

  return (
    <>
      <Header user={user} loading={loadingUser} mobile={viewportWidth >= mobileSize ? false: true}/>
      <Routes> 
        <Route path='/' element={<Home viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/leaderboards' element={<Leaderboards viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/relic-scorer' element={<RelicScorer viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/guides' element={<Guides viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/uid/:userId' element={<UserProfile viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/u/:vanityParam' element={<UserProfile viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/search' element={<Search viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/contribute' element={<Contribute viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/privacy' element={<Privacy viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/contact' element={<Contact viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/login' element={<Login viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/sign-up' element={<SignUp viewportWidth={viewportWidth} mobileSize={mobileSize}/>}/>
        <Route path='/dashboard' element={viewportWidth < mobileSize
          ? <DashboardMobile user={user}/>
          : <Dashboard user={user}/>
        }/>
      </Routes>
      <Footer />
      {viewportWidth < mobileSize && <MobileMenu user={user} />}
    </>
  )
}

export default App;