import React from 'react'

function CustomBasis({customWeightMap, setCustomWeightMap}) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', maxWidth: '480px'}}>
        <textarea 
            defaultValue={JSON.stringify(customWeightMap)}
            onBlur={e => {
            try {
                setCustomWeightMap(JSON.parse(e.target.value));
            } catch (error) {
                alert("Error parsing weight map. You will get unexpected results.")
                console.error("Error parsing weight map:", error);
            }
            }}
            style={{ resize: "none", height: "80px" }} 
        />
        <p>JSON Notation:</p>
        <small>perc = %</small>
        <small>values are automatically bounded between 0 and 2.</small>
        <small>sets is an array of strings in the form: Firesmith of Lava-Forging = "folf"</small>
        <small>body, feet, sphere and rope are arrays of strings of stat types, eg. ["crate", "cdmg"]</small>
    </div>
  )
}

export default CustomBasis